import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(theme => ({
  panel: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderTop: 'none',
    borderRadius: theme.spacing(0, 0, 0.5, 0.5),
    padding: theme.spacing(1, 2),
    marginTop: 0,
  },
  label: {
    paddingLeft: theme.spacing(2),
    lineHeight: '16px',
    letterSpacing: '0.14px',
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    padding: theme.spacing(0.375),
  },
  image: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    opacity: 1,
    zIndex: 1,
  },
  cancel: {
    color: colors.grey,
  },
  check: {
    color: colors.greenDark,
  },
}));
