import { useFeatureFlagStore } from '@wr/web-shared';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { FC } from 'react';

import { Footer } from '@/components/footer';
import { Header } from '@/components/header';
import { buildContentfulSidekickAttributes } from '@/utils';

import { HeaderProps } from '../headerV2/header.types';
import { useStyles } from './layout.styles';
import { LayoutProps } from './layout.types';

//TODO: remove dynamic loading once feature is production ready. https://worldremit.atlassian.net/browse/CONWEB-4416

const HeaderV2 = dynamic<HeaderProps>(
  () =>
    import('@/components/headerV2/header.component').then(mod => mod.Header),
  {
    ssr: false,
  },
);

const StickyBannersWrapper = dynamic(
  () => import('@/components/sticky-banner/sticky-banner.component.wrapper'),
  {
    ssr: false,
  },
);

export const Layout: FC<LayoutProps> = ({
  sys,
  __typename,
  children,
  breadcrumbs,
  footer,
  header,
  name,
  className,
  languageFromCookie,
  blogTags,
}) => {
  const classes = useStyles();

  // TODO: Remove header v2 feature flag.
  // https://worldremit.atlassian.net/browse/CONWEB-4385
  const { headerV2Enabled } = useFeatureFlagStore(state => state.featureFlags);

  return (
    <>
      <StickyBannersWrapper />

      {header &&
        (headerV2Enabled ? (
          <HeaderV2
            {...header}
            blogTags={blogTags}
            languageFromCookie={languageFromCookie}
          />
        ) : (
          <Header
            {...header}
            languageFromCookie={languageFromCookie}
            blogTags={blogTags}
          />
        ))}

      <main
        data-testid={name}
        className={clsx(className, classes.container)}
        {...buildContentfulSidekickAttributes(sys?.id, __typename, name)}
      >
        {children}
      </main>
      {footer && <Footer {...footer} breadcrumbs={breadcrumbs || null} />}
    </>
  );
};
