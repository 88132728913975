import { Link as MuiLink, LinkProps } from '@mui/material';
import NextLink from 'next/link';
import React from 'react';

export const Link: React.FC<React.PropsWithChildren<LinkProps>> = props => {
  const { href } = props;

  if (href) {
    return (
      <NextLink href={href} passHref legacyBehavior>
        <MuiLink {...props}>{props.children}</MuiLink>
      </NextLink>
    );
  }

  return <MuiLink {...props}>{props.children}</MuiLink>;
};
