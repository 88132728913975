import { ErrorResponse } from '@apollo/client/link/error';
import { isDev } from '@wr/web-shared';
import { ErrorBoundaryContext } from '@wr/web-ui';
import { useCallback, useContext } from 'react';

import { logger } from '@/utils';

const hasGraphQLErrors = (e: ErrorResponse) =>
  e.graphQLErrors || e.networkError;

/**
 * next.js properly handles errors in getServerSideProps on client side we handle render error with ErrorBoundary
 * this hook created to handle async logic errors like event handlers setTimeout, setInterval, fetch etc
 * Hook wraps async or sync original function in try/catch and in case of error logs it and on prod shows error screen to a user
 * For better error logging and for better user experience we should use it for all event handlers and async functions that are called from hooks
 * @param func original function
 * @param shouldShowError if recovery is possible we might not want to show error screen, retrying action in setInterval for example
 * @returns wrapped original function
 */
export function useCallbackWithErrorHandling<T extends Array<unknown>, U>(
  errorLogMessage: string,
  func: (...args: T) => U,
) {
  const showError = useContext(ErrorBoundaryContext);

  async function callbackWithErrorHandlingWrapper(
    ...args: T
  ): Promise<U | undefined> {
    try {
      return await func(...args);
    } catch (e) {
      if (!hasGraphQLErrors(e as ErrorResponse)) {
        logger.error(e, errorLogMessage);
      }

      if (isDev()) {
        throw e;
      }

      showError(e as Error);

      return undefined;
    }
  }

  return useCallback(callbackWithErrorHandlingWrapper, [
    errorLogMessage,
    func,
    showError,
  ]);
}
