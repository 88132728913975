import { DEFAULT_LANGUAGE } from '@wr/web-shared';

import { SSRedirect } from './redirects.types';

const LOGIN_PATH = `account/login`;
export const SENDER_FORM_PATH = 'sender-form';
const FORGOTTEN_PASSWORD_PAGE = 'forgotten-password';
const SUCCESS_PATH = 'success';

export function getForgottenPassword(locale: string, expiredCode?: boolean) {
  const path = `/${locale}/${FORGOTTEN_PASSWORD_PAGE}`;
  return expiredCode ? `${path}?expiredCode=true` : path;
}

export function getHomePageUrl(locale: string = DEFAULT_LANGUAGE) {
  return `/${locale}`;
}

export function getLoginUrl(locale: string = DEFAULT_LANGUAGE, returnUrl = '') {
  const returnUrlPart =
    // protection from open redirect vulnerability
    returnUrl.startsWith('/') && !returnUrl.includes('\\')
      ? `?ReturnUrl=${returnUrl}`
      : '';

  const loginUrl = `/${locale}/${LOGIN_PATH}${returnUrlPart}`;
  return loginUrl;
}

export function getServerSideRedirect(url: string): SSRedirect {
  return {
    redirect: {
      destination: url,
    },
  };
}

export function getSuccessPageUrl(
  id: string,
  locale: string = DEFAULT_LANGUAGE,
) {
  return `/${locale}/${SUCCESS_PATH}/${id}`;
}
