export * from './query-params';
export * from './regex';
export * from './contentful';

export const DEFAULT_404_PAGE_SLUG = '404';
export const SITEMAP_SLUG = 'sitemap';
export const GENERIC_CLP_SLUG = 'generic';
export const GENERIC_CURRENCY_CORRIDOR_SLUG =
  'currency-converter/generic-corridor';
export const GENERIC_CURRENCY_SLUG = 'currency-converter/currencies/generic';
export const CORRESPONDENT_LOCATIONS_SLUG = 'correspondent-locations';

export const DEFAULT_LOCATION = 'gb';
export const DEFAULT_COUNTRY_NAME = 'united-kingdom';
export const DEFAULT_SOCIAL_IMAGE_PATH =
  '/web-cms-assets/next-assets/social-images/logo-with-bg.jpeg';
export const GTM_INITIAL_VALUE = 'information not available';
export const STICKY_BANNERS_WRAPPER_ID = 'banners-wrapper';
export const PHILIPPINES_COUNTRY_CODE_FOR_AB_TEST = 'PH';
export const BLOG_CARDS_PER_PAGE = 12;
export const PHILIPPINES_RECEIVE_COUNTRY_CODE = 'PH';

export const ANDROID = 'Android';
export const IOS = 'iOS';
export const AB_TESTING_HP_CLP_SLUG = 'goto'; // https://worldremit.atlassian.net/browse/CONWEB-2183

export const JSON_TABS_INDENT = 2;

export const WORLD_REMIT = 'WorldRemit';

export const fallbackLocales = ['en', 'fr', 'es'];

export const SMART_LINK_URL = 'https://worldremit.onelink.me';

export const GA_EVENT_RESET_PASSWORD_CONTINUE = {
  event_name: 'CTA_Click',
  event_action: 'reset_password',
  event_label: 'continue',
};

export const GA_EVENT_RESET_PASSWORD_FAQ = {
  event_name: 'CTA_Click',
  event_action: 'reset_password',
  event_label: 'faq_page',
};

export const GA_EVENT_FORGOTTEN_PASSWORD_CONTINUE = {
  event_name: 'CTA_Click',
  event_action: 'forgotten_password',
  event_label: 'continue',
};

export const GA_EVENT_FORGOTTEN_PASSWORD_FAQ = {
  event_name: 'CTA_Click',
  event_action: 'forgotten_password',
  event_label: 'faq_page',
};
