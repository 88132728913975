import { MutationResult } from '@apollo/client';
import { MockedProvider as MockedApolloProvider } from '@apollo/client/testing';
import { bffSchema } from '@wr/web-shared';
import {
  ExchangeRateCalculationType,
  mockPayoutMethod,
  mockReceiveCountry,
  mockSendCountry,
} from '@wr/web-ui';

import {
  CalculatorContext,
  CalculatorContextType,
  MockedCalculatorProviderProps,
} from '@/context';

import { INITIAL_CALCULATOR_ACTIONS } from './calculator.constants';

export const mockedCalculatorContext: CalculatorContextType = {
  sendAmount: 0,
  receiveAmount: 0,
  calculationAmount: 100,
  calculationType: ExchangeRateCalculationType.Send,
  sendCountry: mockSendCountry,
  receiveCountry: mockReceiveCountry,
  payoutMethodId: mockPayoutMethod.code,
  payoutMethods: [mockPayoutMethod],
  isLoading: false,
  exchangeRate: {
    discount: 0,
    fee: 30.5,
    totalToPay: 130.5,
    conversionRate: 4.2,
    crossedOutRate: 0,
    hasPromo: false,
  },
};

export const mockedErrors = {
  INPUT_AMOUNT_TOO_LOW: {
    __typename: 'ValidationCalculationError',
    code: 'INPUT_AMOUNT_TOO_LOW',
    description: 'Input amount is too low, requested amount: 0',
    type: 'LIMITS',
    message:
      'Sorry, provided amount is too low, please enter value higher than 0.00.',
  },
  SERVER_ERROR: {
    __typename: 'GenericCalculationError',
    message:
      'Got a server error when calling pricing-facade: 500 - {"id":"c585ce8e-24a9-47fb-9a81-629c5a67dba5","message":"Fee has not been defined yet for given arguments: [sendCountry= JP, receiveCountry= AR, receiveCurrency= ARS]"}',
    genericType: bffSchema.GenericCalculationErrorType.ServerError,
  },
};

export const createCalculationMock = {
  request: {
    query: bffSchema.CreateCalculation,
    variables: {
      amount: 100,
      type: bffSchema.CalculationType.Send,
      sendCountryCode: mockSendCountry.countryCode,
      sendCurrencyCode: mockSendCountry.currency,
      receiveCountryCode: mockReceiveCountry.countryCode,
      receiveCurrencyCode: mockReceiveCountry.currency,
      payOutMethodCode: mockPayoutMethod.code,
      correspondentId: undefined,
    },
  },
  result: {
    data: {
      createCalculation: {
        calculation: {
          id: 'b0e5799d-fc83-4923-b6ec-355021af44f7',
          informativeSummary: {
            fee: {
              value: { amount: 5.99 },
            },
            appliedPromotions: ['CBP'],
          },
          send: { amount: 100 },
          receive: { amount: 96.4 },
          exchangeRate: { value: 0.964 },
        },
        errors: [],
      },
    },
    loading: false,
    called: true,
  } as MutationResult,
};

export const getCorridorsMock = {
  request: {
    query: bffSchema.GetCorridorsBySendCountrySortedByReceiveCountryName,
    variables: {
      sendCountry: mockSendCountry.countryCode,
    },
  },
  result: {
    data: {
      corridorsBySendCountrySortedByReceiveCountryName: [
        {
          receiveCountry: {
            code: mockReceiveCountry.countryCode,
            name: mockReceiveCountry.name,
            dialCode: mockReceiveCountry.dialCode,
          },
          receiveCurrency: {
            code: mockReceiveCountry.currency,
            name: mockReceiveCountry.currencyName,
          },
        },
      ],
    },
    loading: false,
    called: true,
  } as MutationResult,
};

export const getPayoutMethodsMock = {
  request: {
    query: bffSchema.PayoutMethods,
    variables: {
      sendCountry: mockSendCountry.countryCode,
      receiveCountry: mockReceiveCountry.countryCode,
      receiveCurrency: mockReceiveCountry.currency,
    },
  },
  result: {
    data: {
      payoutMethods: mockPayoutMethod.code,
    },
    loading: false,
    called: true,
  } as MutationResult,
};

export const MockedCalculatorProvider: React.FC<MockedCalculatorProviderProps> = ({
  children,
  state = {},
  dispatch = {},
}) => {
  return (
    <MockedApolloProvider
      mocks={[createCalculationMock, getCorridorsMock, getPayoutMethodsMock]}
      addTypename={false}
    >
      <CalculatorContext.Provider
        value={[
          { ...mockedCalculatorContext, ...state },
          { ...INITIAL_CALCULATOR_ACTIONS, ...dispatch },
        ]}
      >
        {children}
      </CalculatorContext.Provider>
    </MockedApolloProvider>
  );
};
