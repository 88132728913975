import { gql } from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigDecimal: { input: unknown; output: unknown };
  CountryCode: { input: unknown; output: unknown };
  CurrencyCode: { input: unknown; output: unknown };
  Date: { input: unknown; output: unknown };
  DateTime: { input: string; output: string };
  Decimal: { input: unknown; output: unknown };
  JSON: { input: Document; output: Document };
  Object: { input: unknown; output: unknown };
  Url: { input: unknown; output: unknown };
  Void: { input: unknown; output: unknown };
  _Any: { input: unknown; output: unknown };
  _FieldSet: { input: unknown; output: unknown };
};

/** A success flag is returned, the result is present only on successful update/activation. */
export type AccountActivated = {
  __typename?: 'AccountActivated';
  success: Scalars['Boolean']['output'];
};

/** Contains an array of all validation errors occurred during account update/activation. */
export type AccountActivationFailed = {
  __typename?: 'AccountActivationFailed';
  validationErrors: Array<AccountActivationValidationError>;
};

/** Account Activation input data needed to update and activate an existing account. */
export type AccountActivationInput = {
  formFields: Array<ActivationFormField>;
};

export type AccountActivationResult =
  | AccountActivated
  | AccountActivationFailed;

/** Validation error for a single field. */
export type AccountActivationValidationError = {
  __typename?: 'AccountActivationValidationError';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AccountAlreadyExistsResponse = {
  __typename?: 'AccountAlreadyExistsResponse';
  _?: Maybe<Scalars['Boolean']['output']>;
};

/** User's personal data related to a particular account. */
export type AccountDetails = {
  __typename?: 'AccountDetails';
  country?: Maybe<Country>;
  email?: Maybe<Email>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isGreenfieldUser: Scalars['Boolean']['output'];
  language?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mfa?: Maybe<MfaInfo>;
  phoneNumber?: Maybe<PhoneNumber>;
  preferences?: Maybe<Preferences>;
  reference?: Maybe<Scalars['String']['output']>;
  referral?: Maybe<Referral>;
  statistics?: Maybe<AccountStatistics>;
  userGuid?: Maybe<Scalars['String']['output']>;
};

/** Account dynamic field */
export type AccountDetailsDynamicField = {
  __typename?: 'AccountDetailsDynamicField';
  missing: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

/** Account details with dynamic list of fields */
export type AccountDetailsWithMissingFields = {
  __typename?: 'AccountDetailsWithMissingFields';
  fields: Array<AccountDetailsDynamicField>;
  hasMissingFields: Scalars['Boolean']['output'];
  isActivated: Scalars['Boolean']['output'];
};

export type AccountDisabledResponse = {
  __typename?: 'AccountDisabledResponse';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type AccountLockedResponse = {
  __typename?: 'AccountLockedResponse';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type AccountStatistics = {
  __typename?: 'AccountStatistics';
  lastTransactionDate?: Maybe<Scalars['DateTime']['output']>;
  transactionsCount?: Maybe<Scalars['Int']['output']>;
};

export type ActivationFormField = {
  id: Scalars['ID']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ActiveCorridorsResult = {
  __typename?: 'ActiveCorridorsResult';
  sendCountries: Array<Scalars['CountryCode']['output']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export enum AddressCollectionMode {
  LookupMandatory = 'LOOKUP_MANDATORY',
  LookupPrefill = 'LOOKUP_PREFILL',
  LookupPrefillV2 = 'LOOKUP_PREFILL_V2',
  Manual = 'MANUAL',
}

export type AddressesResLoqate = {
  __typename?: 'AddressesResLoqate';
  AdminAreaName?: Maybe<Scalars['String']['output']>;
  Block?: Maybe<Scalars['String']['output']>;
  BuildingName?: Maybe<Scalars['String']['output']>;
  BuildingNumber?: Maybe<Scalars['String']['output']>;
  City?: Maybe<Scalars['String']['output']>;
  Company?: Maybe<Scalars['String']['output']>;
  DataLevel?: Maybe<Scalars['String']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  District?: Maybe<Scalars['String']['output']>;
  DomesticId?: Maybe<Scalars['String']['output']>;
  Id: Scalars['String']['output'];
  Label?: Maybe<Scalars['String']['output']>;
  Language?: Maybe<Scalars['String']['output']>;
  LanguageAlternatives?: Maybe<Scalars['String']['output']>;
  Neighbourhood?: Maybe<Scalars['String']['output']>;
  PostalCode?: Maybe<Scalars['String']['output']>;
  Province?: Maybe<Scalars['String']['output']>;
  ProvinceName?: Maybe<Scalars['String']['output']>;
  SecondaryStreet?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  SubBuilding?: Maybe<Scalars['String']['output']>;
};

/** Supported Adyen3DS challenge types. */
export enum Adyen3DsType {
  Redirect = 'redirect',
  ThreeDs2 = 'threeDS2',
}

/** Adyen configuration contains the configuration for Adyen 3DS. */
export type AdyenConfig = {
  __typename?: 'AdyenConfig';
  /** Unique client key to use for initializing Adyen SDK. */
  clientKey: Scalars['String']['output'];
  /** Environment Key. */
  environment: AdyenEnvironment;
};

/** Adyen Environment keys. */
export enum AdyenEnvironment {
  Apse = 'APSE',
  Australia = 'AUSTRALIA',
  Europe = 'EUROPE',
  India = 'INDIA',
  Test = 'TEST',
  UnitedStates = 'UNITED_STATES',
}

export type AdyenSessionData = {
  __typename?: 'AdyenSessionData';
  returnUrl?: Maybe<Scalars['String']['output']>;
  sessionData?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['ID']['output']>;
};

export type AirtimePurchaseCreated = {
  __typename?: 'AirtimePurchaseCreated';
  cancelUrl?: Maybe<Scalars['String']['output']>;
  failureUrl?: Maybe<Scalars['String']['output']>;
  paymentMethodImplementationId?: Maybe<Scalars['ID']['output']>;
  paymentSessionId?: Maybe<Scalars['String']['output']>;
  paymentUrl?: Maybe<Scalars['String']['output']>;
  purchaseId?: Maybe<Scalars['ID']['output']>;
  sessionData?: Maybe<SessionData>;
  successUrl?: Maybe<Scalars['String']['output']>;
  transferId?: Maybe<Scalars['ID']['output']>;
};

export type ApplePayPaymentAuthorizationInput = {
  paymentSessionId: Scalars['ID']['input'];
  resultCode?: InputMaybe<PaymentResultCode>;
};

export type ApplePayPaymentAuthorized = {
  __typename?: 'ApplePayPaymentAuthorized';
  paymentStatus: PaymentStatus;
};

export type ApplePaySessionInitializationInput = {
  paymentSessionId: Scalars['ID']['input'];
};

export type ApplePaySessionInitialized = {
  __typename?: 'ApplePaySessionInitialized';
  sessionData: Scalars['String']['output'];
  sessionId: Scalars['ID']['output'];
};

export enum AppliedPromotions {
  Cbp = 'CBP',
}

export type AvailableCouponsInput = {
  correspondentId?: InputMaybe<Scalars['String']['input']>;
  payOutMethodCode?: InputMaybe<Scalars['String']['input']>;
  receive: CountryCurrencyPair;
  send: CountryCurrencyPair;
  sendAmount?: InputMaybe<Scalars['BigDecimal']['input']>;
};

/** Coupon which can be used by user to get better deal. */
export type AvailableCouponsResult = {
  __typename?: 'AvailableCouponsResult';
  amount?: Maybe<Money>;
  autoApplicable?: Maybe<Scalars['Boolean']['output']>;
  expiry?: Maybe<Scalars['DateTime']['output']>;
  minimalTransfer?: Maybe<Money>;
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  type?: Maybe<Promotion>;
};

export type BankTransferDetails = {
  __typename?: 'BankTransferDetails';
  accountBic?: Maybe<Scalars['String']['output']>;
  accountIban?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  bankCode?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  branchCode?: Maybe<Scalars['String']['output']>;
  branchName?: Maybe<Scalars['String']['output']>;
};

export enum BannerCtaType {
  FailedSendAgain = 'FailedSendAgain',
}

export type BeginMfaInput = {
  scope?: InputMaybe<Scalars['String']['input']>;
  type: MfaFactorType;
  userId: Scalars['String']['input'];
};

export type BeginMfaResponse = {
  __typename?: 'BeginMFAResponse';
  token: Scalars['String']['output'];
};

/**
 * Base interface that should be used for all business errors in our schema. \
 * Thanks to that, clients can define and reuse common fragments.
 */
export type BusinessError = {
  message: Scalars['String']['output'];
};

/** Interface for all business error types that return multiple business errors. */
export type BusinessErrorsList = {
  errors: Array<BusinessError>;
};

export type Calculation = {
  __typename?: 'Calculation';
  amountType?: Maybe<CalculationType>;
  compliance?: Maybe<TransferComplianceCheckResult>;
  correspondentId?: Maybe<Scalars['Int']['output']>;
  corridor?: Maybe<MoneyTransferCorridor>;
  dynamicPricingCalculations?: Maybe<Array<Maybe<DynamicPricing>>>;
  exchangeRate: FxRate;
  id?: Maybe<Scalars['String']['output']>;
  informativeSummary: InformativeSummary;
  isFree: Scalars['Boolean']['output'];
  payInMethodsCalculations?: Maybe<Array<Maybe<PayInMethodCalculation>>>;
  payOutMethodCode?: Maybe<Scalars['String']['output']>;
  receive: Money;
  rounding?: Maybe<Rounding>;
  send: Money;
};

export type CalculationError = {
  message: Scalars['String']['output'];
};

/**
 * Input that has to be supplied to create a new calculation. Note: `promoCode` is optional, however if provided, *Authorization*
 * token must be added to the request. Otherwise the request will be rejected by pricing-facade service.
 */
export type CalculationInput = {
  amount: Scalars['BigDecimal']['input'];
  correspondentId?: InputMaybe<Scalars['String']['input']>;
  payOutMethodCode?: InputMaybe<Scalars['String']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  receive: CountryCurrencyPair;
  send: CountryCurrencyPair;
  type: CalculationType;
};

/** Indicates if calculation amount provided by a user is amount which is send by him/her or should be received by a recipient. */
export enum CalculationType {
  Receive = 'RECEIVE',
  Send = 'SEND',
}

export type CancellationDetails = {
  __typename?: 'CancellationDetails';
  cancellationType?: Maybe<CancellationType>;
  /** @deprecated No longer supported */
  content?: Maybe<Scalars['String']['output']>;
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /**  This field will be present only if isAvailable is true */
  reason?: Maybe<CancellationDetailsReason>;
  /** @deprecated No longer supported */
  title?: Maybe<Scalars['String']['output']>;
};

/** Reason for the transfer cancellation decision. This will be used to provide more context to the user. */
export enum CancellationDetailsReason {
  NoPartnerSupport = 'NoPartnerSupport',
}

/** Type of the transfer cancellation. This will determine the cancellation process. */
export enum CancellationType {
  ContactCustomerCare = 'ContactCustomerCare',
  SelfService = 'SelfService',
}

export type Card = {
  __typename?: 'Card';
  cardNumberLastFourDigits?: Maybe<Scalars['String']['output']>;
  cardScheme?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
};

export type CardExpiry = {
  __typename?: 'CardExpiry';
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type CardMetadata = {
  __typename?: 'CardMetadata';
  binRangeFrom: Scalars['String']['output'];
  binRangeTo: Scalars['String']['output'];
  cardSegment: CardSegment;
  cardType: CardType;
  errorCodes: Array<CardValidationError>;
  primaryScheme: CardScheme;
  secondaryScheme?: Maybe<CardScheme>;
};

export type CardScheme = {
  __typename?: 'CardScheme';
  isSupported: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export enum CardSegment {
  Commercial = 'COMMERCIAL',
  Consumer = 'CONSUMER',
  UnknownSegment = 'UNKNOWN_SEGMENT',
}

export type CardType = {
  __typename?: 'CardType';
  name: Scalars['String']['output'];
};

export enum CardValidationError {
  SenderAndCardIssuerCountryMismatch = 'SENDER_AND_CARD_ISSUER_COUNTRY_MISMATCH',
  UnknownError = 'UNKNOWN_ERROR',
  UnsupportedCardSchemes = 'UNSUPPORTED_CARD_SCHEMES',
  UnsupportedCardSegment = 'UNSUPPORTED_CARD_SEGMENT',
  UnsupportedCardType = 'UNSUPPORTED_CARD_TYPE',
}

export type CashPickupDetails = {
  __typename?: 'CashPickupDetails';
  locationsDescription?: Maybe<Scalars['String']['output']>;
  locationsLink?: Maybe<Scalars['String']['output']>;
  phrase?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
};

export type Chat = {
  __typename?: 'Chat';
  contactId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  isActive: Scalars['Boolean']['output'];
};

export type ChatTranscripts = {
  __typename?: 'ChatTranscripts';
  transcripts: Array<Transcript>;
};

export type ChatTranscriptsFilterInput = {
  contactId: Scalars['String']['input'];
  /**
   * Represents the created-date of the chat whose transcripts are being queried
   * format: yyyy-MM-ddTHH:mm:ss.SSSZ e.g. 2024-04-28T11:56:889.871Z
   */
  createdAt: Scalars['String']['input'];
};

export type Check = {
  __typename?: 'Check';
  date: Scalars['String']['output'];
  isRetry: Scalars['Boolean']['output'];
  provider: CheckProviderResponse;
  status: CheckStatusResponse;
  type: CheckTypeResponse;
};

export enum CheckProviderResponse {
  Legacy = 'LEGACY',
  Onfido = 'ONFIDO',
  Passfort = 'PASSFORT',
}

export enum CheckStatusResponse {
  Failed = 'FAILED',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export enum CheckTypeResponse {
  DocumentVerification = 'DOCUMENT_VERIFICATION',
  IdentityVerification = 'IDENTITY_VERIFICATION',
}

export type CompleteMfaInput = {
  code: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type CompleteMfaResponse =
  | AccountLockedResponse
  | MfaCodeExpiredResponse
  | MfaCodeInvalidResponse
  | MfaSuccessResponse;

export type ComponentValidationRules = {
  __typename?: 'ComponentValidationRules';
  maxDate?: Maybe<Scalars['Int']['output']>;
  maxDateScale?: Maybe<Scalars['String']['output']>;
  maxLength?: Maybe<Scalars['Int']['output']>;
  minDate?: Maybe<Scalars['Int']['output']>;
  minDateScale?: Maybe<Scalars['String']['output']>;
  minLength?: Maybe<Scalars['Int']['output']>;
  pattern?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
};

export type ContactData = {
  __typename?: 'ContactData';
  email?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type Context = {
  correspondentId: Scalars['Int']['input'];
  productCode: Scalars['String']['input'];
  receiveCountryCode: Scalars['String']['input'];
  sendCountryCode: Scalars['String']['input'];
};

export type Correspondent = {
  __typename?: 'Correspondent';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  payOutMethod?: Maybe<PayOutMethod>;
  payOutOptions?: Maybe<PayOutOptions>;
  payOutTime?: Maybe<Scalars['String']['output']>;
  payOutTimeOrder?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<CorrespondentStatus>;
};

export type CorrespondentLocation = {
  __typename?: 'CorrespondentLocation';
  address?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  correspondentName: Scalars['String']['output'];
  country: Scalars['String']['output'];
  hours?: Maybe<Scalars['String']['output']>;
  officeName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

/** CorrespondentLocation Connection */
export type CorrespondentLocationConnection = {
  __typename?: 'CorrespondentLocationConnection';
  /** Field edges */
  edges?: Maybe<Array<Maybe<CorrespondentLocationEdge>>>;
  /** Field pageInfo */
  pageInfo?: Maybe<PageInfo>;
};

/** CorrespondentLocation Edge */
export type CorrespondentLocationEdge = {
  __typename?: 'CorrespondentLocationEdge';
  /** Field cursor */
  cursor?: Maybe<Scalars['String']['output']>;
  /** Field node */
  node?: Maybe<CorrespondentLocation>;
};

export type CorrespondentLocationInput = {
  correspondentIds: Array<Scalars['ID']['input']>;
  countryCode: Scalars['CountryCode']['input'];
};

export enum CorrespondentStatus {
  Degraded = 'DEGRADED',
  Disrupted = 'DISRUPTED',
  Enabled = 'ENABLED',
  Retired = 'RETIRED',
}

export type CorrespondentsInput = {
  payOutMethodCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountry: Scalars['CountryCode']['input'];
  receiveCurrency: Scalars['CurrencyCode']['input'];
  sendCountry: Scalars['CountryCode']['input'];
};

/**
 * Product transfer's corridor
 *
 * Marked as @draft: Subject to breaking changes and removal. Not available in production.
 */
export type Corridor = {
  __typename?: 'Corridor';
  correspondentId?: Maybe<Scalars['Int']['output']>;
  payOutMethodCode?: Maybe<Scalars['String']['output']>;
  receive?: Maybe<CorridorCountry>;
  receiveCountry: Country;
  receiveCurrency: LocalizedCurrency;
  send?: Maybe<CorridorCountry>;
  sendCountry: Country;
  sendCurrency: LocalizedCurrency;
};

export type CorridorCountry = {
  __typename?: 'CorridorCountry';
  country: Scalars['String']['output'];
  currency: Scalars['String']['output'];
};

export type CorridorsToSearchInput = {
  receiveCountry: Scalars['CountryCode']['input'];
  sendCountries: Array<Scalars['CountryCode']['input']>;
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['CountryCode']['output'];
  currency?: Maybe<LocalizedCurrency>;
  dialCode?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type CountryCurrency = {
  country: Scalars['String']['input'];
  currency: Scalars['String']['input'];
};

export type CountryCurrencyPair = {
  country: Scalars['CountryCode']['input'];
  currency: Scalars['CurrencyCode']['input'];
};

export enum CountrySortBy {
  Code = 'CODE',
  DialCode = 'DIAL_CODE',
  Name = 'NAME',
  ShortName = 'SHORT_NAME',
}

/** Defines the sorting attributes to sort a country list. Default orderBy value is ASC. */
export type CountrySortingAttributes = {
  countrySortBy: CountrySortBy;
  sortingOrder?: InputMaybe<SortingOrder>;
};

export type CountrySummary = {
  __typename?: 'CountrySummary';
  code: Scalars['CountryCode']['output'];
};

export type CreateAirtimePurchaseInput = {
  cancelUrl: Scalars['String']['input'];
  couponCode?: InputMaybe<Scalars['String']['input']>;
  failureUrl: Scalars['String']['input'];
  payInMethodId: Scalars['String']['input'];
  pricingId: Scalars['ID']['input'];
  productId?: InputMaybe<Scalars['ID']['input']>;
  receiveCountry: Scalars['String']['input'];
  recipientId: Scalars['ID']['input'];
  sendCountry: Scalars['String']['input'];
  successUrl: Scalars['String']['input'];
  trackingSessionId?: InputMaybe<Scalars['String']['input']>;
};

/** A union of all possible errors related to `createCalculation` mutation. */
export type CreateCalculationError =
  | GenericCalculationError
  | ValidationCalculationError;

export type CreateCalculationResult = {
  __typename?: 'CreateCalculationResult';
  calculation?: Maybe<Calculation>;
  errors: Array<CreateCalculationError>;
};

export type CreatePaymentInput = {
  cancelUrl: Scalars['String']['input'];
  cardRegistrationId?: InputMaybe<Scalars['ID']['input']>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
  failureUrl: Scalars['String']['input'];
  payInMethodId: Scalars['ID']['input'];
  payOutMethodCode: Scalars['String']['input'];
  pricingId: Scalars['ID']['input'];
  receiveCountry: Scalars['CountryCode']['input'];
  recipientId: Scalars['ID']['input'];
  sendCountry: Scalars['CountryCode']['input'];
  successUrl: Scalars['String']['input'];
  trackingSessionId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserFxRateAlertFailed = {
  __typename?: 'CreateUserFxRateAlertFailed';
  errors: Array<UserFxRateAlertValidationError>;
};

export type CreateUserFxRateAlertResult =
  | CreateUserFxRateAlertFailed
  | UserFxRateAlert;

export type CreateUserFxRateAlertResultV2 =
  | CreateUserFxRateAlertFailed
  | CreateUserFxRateAlertSucceeded;

export type CreateUserFxRateAlertSucceeded = {
  __typename?: 'CreateUserFxRateAlertSucceeded';
  id: Scalars['ID']['output'];
};

/** Contains getting failure message, the result is present only on getting failure */
export type CustomerComplianceFailed = {
  __typename?: 'CustomerComplianceFailed';
  message: Scalars['String']['output'];
};

/** Customer Compliance status request */
export type CustomerComplianceRequest = {
  receiveCountry?: InputMaybe<Scalars['String']['input']>;
  transferAmount?: InputMaybe<TransferComplianceMoney>;
};

export type CustomerComplianceResponse =
  | CustomerComplianceFailed
  | CustomerComplianceSucceed;

export enum CustomerComplianceStatus {
  Compliant = 'Compliant',
  NotCompliant = 'NotCompliant',
}

/** Getting Customer Compliance status succeed response */
export type CustomerComplianceSucceed = {
  __typename?: 'CustomerComplianceSucceed';
  canTransact: Scalars['Boolean']['output'];
  gracePeriod?: Maybe<Scalars['Int']['output']>;
  missingDataUnits?: Maybe<Array<MissingDataUnit>>;
  requiredChecks?: Maybe<Array<Check>>;
  status: CustomerComplianceStatus;
};

/** Input for deleting saved cards. */
export type DeleteCardInput = {
  /** String array of card ids to delete. */
  cards: Array<Scalars['ID']['input']>;
};

export type DeleteSavedAccountInput = {
  accountId: Scalars['ID']['input'];
  paymentMethodId: Scalars['String']['input'];
};

export type DeliveryDetails = {
  __typename?: 'DeliveryDetails';
  bankTransferDetails?: Maybe<BankTransferDetails>;
  cashPickupDetails?: Maybe<CashPickupDetails>;
  pushToVisa?: Maybe<PushToVisaDetails>;
  pushToVisaDetails?: Maybe<PushToVisaDetails>;
};

/** Timeline Event for DetailedTimeline */
export type DetailedTimelineEvent = {
  __typename?: 'DetailedTimelineEvent';
  content: EventStatusContent;
  date?: Maybe<Scalars['DateTime']['output']>;
  state: TransferTimelineEventState;
  status: DetailedTimelineEventStatus;
};

export enum DetailedTimelineEventStatus {
  AwaitingKycVerification = 'AwaitingKycVerification',
  AwaitingPayment = 'AwaitingPayment',
  CancellationInProgress = 'CancellationInProgress',
  Cancelled = 'Cancelled',
  CorrespondentOutage = 'CorrespondentOutage',
  Created = 'Created',
  Delivered = 'Delivered',
  Failed = 'Failed',
  InProgress = 'InProgress',
  InvalidRecipientDetails = 'InvalidRecipientDetails',
  PauseInRefund = 'PauseInRefund',
  ReadyForPickup = 'ReadyForPickup',
  RefundProcessed = 'RefundProcessed',
  WithPartners = 'WithPartners',
}

export type DetailedTransferStatus = {
  __typename?: 'DetailedTransferStatus';
  code?: Maybe<DetailedTransferStatusCode>;
};

export enum DetailedTransferStatusCode {
  CancellationPending = 'CancellationPending',
  Cancelled = 'Cancelled',
  Delayed = 'Delayed',
  Delivered = 'Delivered',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Pending = 'Pending',
  PendingTask = 'PendingTask',
}

export type DetailedTransferTimeline = {
  __typename?: 'DetailedTransferTimeline';
  /** An ordered list of completed, current and expected events. */
  events: Array<DetailedTimelineEvent>;
};

export enum Direction {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing',
}

export type Discount = {
  __typename?: 'Discount';
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  promoCode?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value: Money;
};

export type DiscountCalculationInput = {
  calculationId: Scalars['ID']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
};

export type DiscountCalculationResult = {
  __typename?: 'DiscountCalculationResult';
  calculation?: Maybe<Calculation>;
  errors: Array<CreateCalculationError>;
};

/**
 * Input that has to be supplied to discount an existing single product calculation.
 *
 * Marked as @draft: Subject to breaking changes and removal. Not available in production.
 */
export type DiscountProductCalculationInput = {
  calculationId: Scalars['ID']['input'];
  coupon: Scalars['String']['input'];
  productId: Scalars['ID']['input'];
};

/** Marked as @draft: Subject to breaking changes and removal. Not available in production. */
export type DiscountProductCalculationResult = {
  __typename?: 'DiscountProductCalculationResult';
  calculation?: Maybe<SingleProductCalculation>;
  errors?: Maybe<Array<Maybe<CreateCalculationError>>>;
};

export type DownloadableReceipt = {
  __typename?: 'DownloadableReceipt';
  url: Scalars['Url']['output'];
};

export type DynamicBanner = {
  __typename?: 'DynamicBanner';
  backgroundColor: Scalars['String']['output'];
  ctaType?: Maybe<BannerCtaType>;
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  iconColor: Scalars['String']['output'];
  iconUrl: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type DynamicComponent = {
  __typename?: 'DynamicComponent';
  addressCollectionMode?: Maybe<AddressCollectionMode>;
  addressLookupEditLabel?: Maybe<Scalars['String']['output']>;
  addressLookupEnabled?: Maybe<Scalars['Boolean']['output']>;
  addressLookupMapping?: Maybe<Array<Scalars['String']['output']>>;
  addressManualEditLabel?: Maybe<Scalars['String']['output']>;
  alignText?: Maybe<Scalars['String']['output']>;
  calculateValue?: Maybe<Scalars['String']['output']>;
  category?: Maybe<FieldCategory>;
  components?: Maybe<Array<DynamicComponent>>;
  conditional?: Maybe<Scalars['JSON']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  continueButtonLabel?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  dataPlaceholder?: Maybe<Scalars['String']['output']>;
  dayFirst?: Maybe<Scalars['Boolean']['output']>;
  defaultValue?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayType?: Maybe<Scalars['String']['output']>;
  editLabel?: Maybe<Scalars['String']['output']>;
  editableAfterActivation?: Maybe<Scalars['Boolean']['output']>;
  editableBeforeActivation?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<DynamicFormError>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  iconName?: Maybe<Scalars['String']['output']>;
  inputMask?: Maybe<Scalars['String']['output']>;
  invalidRecipientError?: Maybe<InvalidRecipientError>;
  isAirtimeNumber?: Maybe<Scalars['Boolean']['output']>;
  isMobileNumber?: Maybe<Scalars['Boolean']['output']>;
  isNumericField?: Maybe<Scalars['Boolean']['output']>;
  isValidationRequiredAfterActivation?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  maxDate?: Maybe<Scalars['String']['output']>;
  minDate?: Maybe<Scalars['String']['output']>;
  mobLabelPosition?: Maybe<Scalars['String']['output']>;
  multiple?: Maybe<Scalars['Boolean']['output']>;
  originalKey?: Maybe<Scalars['String']['output']>;
  placeholder?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  refreshOn?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tooltip?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  typographyVariant?: Maybe<Scalars['String']['output']>;
  uniqueId?: Maybe<Scalars['ID']['output']>;
  validate?: Maybe<ComponentValidationRules>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DynamicForm = {
  __typename?: 'DynamicForm';
  components: Scalars['JSON']['output'];
  typedComponents?: Maybe<Array<DynamicComponent>>;
};

export type DynamicFormError = {
  __typename?: 'DynamicFormError';
  invalid_day?: Maybe<Scalars['String']['output']>;
  maxDate?: Maybe<Scalars['String']['output']>;
  maxLength?: Maybe<Scalars['String']['output']>;
  maxWords?: Maybe<Scalars['String']['output']>;
  minDate?: Maybe<Scalars['String']['output']>;
  minLength?: Maybe<Scalars['String']['output']>;
  minWords?: Maybe<Scalars['String']['output']>;
  pattern?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['String']['output']>;
};

/** Contains information dynamic pricing. */
export type DynamicPricing = {
  __typename?: 'DynamicPricing';
  exchangeRate?: Maybe<Scalars['BigDecimal']['output']>;
  originalExchangeRate?: Maybe<Scalars['BigDecimal']['output']>;
  receiveAmount?: Maybe<Money>;
  sendAmount?: Maybe<Money>;
  sendLowerBound?: Maybe<Scalars['BigDecimal']['output']>;
  sendUpperBound?: Maybe<Scalars['BigDecimal']['output']>;
};

export type DynamicTransferStatus = {
  __typename?: 'DynamicTransferStatus';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  iconColor?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  secondaryStatusColor?: Maybe<Scalars['String']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
};

export type Email = {
  __typename?: 'Email';
  email?: Maybe<Scalars['String']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  verificationDetails?: Maybe<VerificationDetails>;
};

export type Entry = {
  __typename?: 'Entry';
  json?: Maybe<Scalars['JSON']['output']>;
  typename?: Maybe<Scalars['String']['output']>;
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN',
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN',
}

export type EventStatusContent = {
  __typename?: 'EventStatusContent';
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
};

export type ExchangeRateCalculation = {
  __typename?: 'ExchangeRateCalculation';
  exchangeRate?: Maybe<Scalars['BigDecimal']['output']>;
  maxAmount?: Maybe<Money>;
};

/** Input for submitting a payment transaction using a saved card. */
export type ExistingCardPaymentInput = {
  cardId: Scalars['ID']['input'];
  cvv: Scalars['String']['input'];
  paymentSessionId: Scalars['ID']['input'];
};

export type FeatureFlagResponse = {
  __typename?: 'FeatureFlagResponse';
  enabled: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type FeatureFlagState = {
  __typename?: 'FeatureFlagState';
  config?: Maybe<Scalars['JSON']['output']>;
  isEnabled: Scalars['Boolean']['output'];
};

export type Fee = {
  __typename?: 'Fee';
  type?: Maybe<Scalars['String']['output']>;
  value: Money;
};

export type Field = {
  __typename?: 'Field';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum FieldCategory {
  Activation = 'activation',
  All = 'all',
  Signup = 'signup',
}

export type FileUploadRequest = {
  documentType: Scalars['String']['input'];
  file: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  zboTaskId: Scalars['String']['input'];
};

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
};

export type FormField = {
  id: Scalars['ID']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type FpxSessionInitializationInput = {
  paymentSessionId: Scalars['ID']['input'];
};

export type FpxSessionInitialized = {
  __typename?: 'FpxSessionInitialized';
  returnUrl?: Maybe<Scalars['String']['output']>;
  sessionData: Scalars['String']['output'];
  sessionId: Scalars['ID']['output'];
};

/** Exchange rate representation. */
export type FxRate = {
  __typename?: 'FxRate';
  crossedOutValue?: Maybe<Scalars['BigDecimal']['output']>;
  exchangeRateCalculations?: Maybe<Array<Maybe<ExchangeRateCalculation>>>;
  value: Scalars['BigDecimal']['output'];
};

/** A general-purpose error for createCalculation mutation. Currently in use for almost all 4XX and 5XX errors. */
export type GenericCalculationError = CalculationError & {
  __typename?: 'GenericCalculationError';
  message: Scalars['String']['output'];
  type: GenericCalculationErrorType;
};

export enum GenericCalculationErrorType {
  ClientError = 'CLIENT_ERROR',
  InternalError = 'INTERNAL_ERROR',
  ServerError = 'SERVER_ERROR',
  UnknownError = 'UNKNOWN_ERROR',
}

export type GetCalculationInput = {
  calculationId: Scalars['ID']['input'];
};

export type GetCalculationResult = {
  __typename?: 'GetCalculationResult';
  calculation?: Maybe<Calculation>;
  errors: Array<GenericCalculationError>;
};

export type GetSavedAccountsInput = {
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};

/** Generic version of icon type (mapped to JSON directly). */
export type Icon = {
  __typename?: 'Icon';
  resolutions: Scalars['JSON']['output'];
};

/** Informational preview of transfer costs. */
export type InformativeSummary = {
  __typename?: 'InformativeSummary';
  appliedPromotions?: Maybe<Array<Maybe<AppliedPromotions>>>;
  discount?: Maybe<Fee>;
  fee: Fee;
  totalToPay: Money;
};

/** The input for the payment flow initialization. */
export type InitPaymentInput = {
  paymentSessionId: Scalars['ID']['input'];
};

export type InitiateChatResponse = {
  __typename?: 'InitiateChatResponse';
  /** AWS region. */
  awsRegion?: Maybe<Scalars['String']['output']>;
  /** AWS contact ID. */
  contactId?: Maybe<Scalars['ID']['output']>;
  /**
   * A flag to determine if the chat is a new one (no messages) or if user should pull
   * history.
   */
  isExisting?: Maybe<Scalars['Boolean']['output']>;
  /** AWS participant ID. */
  participantId?: Maybe<Scalars['ID']['output']>;
  /** AWS participant token. */
  participantToken?: Maybe<Scalars['String']['output']>;
};

export type InterpolationsTuple = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Common types */
export type InvalidCredentialsResponse = {
  __typename?: 'InvalidCredentialsResponse';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type InvalidRecipientError = {
  __typename?: 'InvalidRecipientError';
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export type Language = {
  __typename?: 'Language';
  langCode?: Maybe<Scalars['String']['output']>;
  localizedName?: Maybe<Scalars['String']['output']>;
  originalName?: Maybe<Scalars['String']['output']>;
};

export type LocalizedCurrency = {
  __typename?: 'LocalizedCurrency';
  code: Scalars['CurrencyCode']['output'];
  name: Scalars['String']['output'];
};

export type MfaCodeExpiredResponse = {
  __typename?: 'MFACodeExpiredResponse';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type MfaCodeInvalidResponse = {
  __typename?: 'MFACodeInvalidResponse';
  _?: Maybe<Scalars['Boolean']['output']>;
  nextAttemptAfter: Scalars['String']['output'];
};

export enum MfaFactorType {
  Sms = 'SMS',
}

export type MfaInfo = {
  __typename?: 'MFAInfo';
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type MfaRequiredResponse = {
  __typename?: 'MFARequiredResponse';
  userId: Scalars['String']['output'];
};

export type MfaSuccessResponse = {
  __typename?: 'MFASuccessResponse';
  accessToken: Scalars['String']['output'];
};

export type MapEntry = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MatchAddressReqLoqate = {
  container?: InputMaybe<Scalars['String']['input']>;
  countries?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  pattern: Scalars['String']['input'];
};

export type MatchAddressResLoqate = {
  __typename?: 'MatchAddressResLoqate';
  Description?: Maybe<Scalars['String']['output']>;
  Highlight?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Text?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

export type MissingDataUnit = {
  __typename?: 'MissingDataUnit';
  name: Scalars['String']['output'];
};

export type Modules = {
  __typename?: 'Modules';
  items?: Maybe<Array<Maybe<Entry>>>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['BigDecimal']['output'];
  currency: Scalars['CurrencyCode']['output'];
};

/** Money transfer's corridor */
export type MoneyTransferCorridor = {
  __typename?: 'MoneyTransferCorridor';
  receive: CorridorCountry;
  send: CorridorCountry;
};

/** Indicates what kind of estimate was returned - it may vary depending on various factors. */
export enum MoneyTransferPriceEstimateType {
  /**
   * For a given set of values for specified parameters, the price varies depending on values of skipped
   * parameters and the maximum price was selected as estimate.
   */
  Maximum = 'Maximum',
  /**
   * For a given set of values for specified parameters, the price varies depending on values of skipped
   * parameters and the minimum price was selected as estimate.
   */
  Minimum = 'Minimum',
  /** Preview estimate, which may not reflect guaranteed prices. */
  Preview = 'Preview',
  /**
   * For a given set of values for specified parameters, the price remains uniform regardless of values of
   * skipped parameters.
   */
  Uniform = 'Uniform',
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Authorizes existing card payment session and returns the PaymentResult object that contains all the
   * information required to complete the payment session.
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  authoriseExistingCardPaymentSession: PaymentResult;
  /**
   * Authorizes new card payment session and returns the PaymentResult object that contains all the
   * information required to complete the payment session.
   *
   * Required HTTP headers:
   * * Authorization
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  authoriseNewCardPaymentSession: PaymentResult;
  /**
   * A mutation to authorize apple pay payment. As a result client can continue with the apple pay payment. \
   * \
   * Required headers:
   * * Authorization
   */
  authorizeApplePayPaymentSession: ApplePayPaymentAuthorized;
  /**
   * A mutation to send a request for an mfa token for a user. Requires userId and mfaType. \
   * \
   * Returns a MFA token.\
   * \
   * Calls authentication service to begin the mfa process.
   */
  beginMFA: BeginMfaResponse;
  /**
   * A mutation to perform transfer cancellation action of a particular transfer. Requires transfer id. \
   * \
   * Returns a cancellation result. \
   * \
   * Required HTTP headers:
   * * Authorization
   */
  cancelTransfer: TransferCancellationResult;
  /**
   * Required headers:
   * * Authorization
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  changePassword?: Maybe<Scalars['Void']['output']>;
  /**
   * Completes payment session that requires 3ds authentication.
   *
   * Required HTTP headers:
   * * Authorization
   */
  complete3DSPaymentSession: PaymentResult;
  /**
   * A mutation to send a request to validate a mfa token. Requires token and mfa token code that the user received via SMS. \
   * \
   * Returns an access token if token verification is successful.\
   * \
   * Returns code expired response If the code expired.\
   * \
   * Returns code invalid response If provided code is invalid or failed verification check.\
   * \
   * Calls authentication service to complete the mfa process.
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  completeMFA: CompleteMfaResponse;
  /**
   *  TODO: Would be nice if we could only skip the logging for the `properties` argument, and not all of them, but putting the @sensitive directive there doesn't work
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  completeStep?: Maybe<StepCompletionResult>;
  /**
   * A mutation to create airtime purchase. \
   * \
   * Calls RTM. \
   * \
   * Required headers:
   * * Authorization
   * * X-WR-Platform
   * * X-WR-RequestId
   */
  createAirtimePurchase: AirtimePurchaseCreated;
  /**
   * A mutation to create a new interim calculation or calculation preview from given input. \
   * \
   * Calls pre-submission-orchestrator underneath. \
   * \
   * Required HTTP headers:
   * * Authorization (only for requests with promoCode)
   * * X-WR-Platform
   */
  createCalculation: CreateCalculationResult;
  /**
   * A mutation to create payment (transaction). As a result, user gets information about created payment. \
   * \
   * Required headers:
   * * Authorization
   * * X-WR-Platform
   */
  createPayment?: Maybe<PaymentCreated>;
  /**
   * A mutation to create a new interim product calculation. \
   * \
   * Calls pre-submission-orchestrator underneath. \
   * \
   * Required HTTP headers:
   * * X-WR-Platform [Android, Web, iOS]
   * * X-WR-PlatformVersion (Not required for Web, required for iOS and Androids)
   * * X-WR-CorrelationId
   * * X-WR-RequestId
   * * X-WR-TransferId
   * * X-WR-CallerId
   * * Accept-Language (Default value : en)
   */
  createProductsCalculation: ProductsCalculationResult;
  /**
   * A mutation to create a new interim quote calculation or quote calculation preview from given input. \
   * \
   * Calls pre-submission-orchestrator underneath. \
   * \
   * Required HTTP headers:
   * * Authorization (only for requests with promoCode)
   * * X-WR-Platform
   */
  createQuote: CreateCalculationResult;
  /**
   * A mutation to request creation of Riskified session connected with transfer. \
   * \
   * Required HTTP headers:
   * * Authorization
   * * User-Agent
   * * Accept-Language
   */
  createRiskifiedSessionMapping?: Maybe<Scalars['Void']['output']>;
  /**
   * A mutation to request creation of ThreatMetrix session connected with transfer. \
   * \
   * Required HTTP headers:
   * * Authorization
   * * X-WR-RequestId
   * * X-WR-Platform
   * @deprecated No longer supported
   */
  createThreatMetrixSessionMapping?: Maybe<Scalars['Void']['output']>;
  /**
   * Subscribes user to a new alert and returns this new alert to the user. \
   * \
   * Required headers:
   * * Authorization
   */
  createUserFxRateAlert: CreateUserFxRateAlertResult;
  /**
   * Subscribes user to a new alert and returns this new alert to the user. \
   * \
   * Required headers:
   * * Authorization
   */
  createUserFxRateAlertV2: CreateUserFxRateAlertResultV2;
  createWallet: Wallet;
  deleteSavedBankAccount?: Maybe<Scalars['Void']['output']>;
  /**
   * Deletes a user saved card(s) for the logged in user.
   *
   * Required HTTP headers:
   * * Authorization
   */
  deleteSavedCards?: Maybe<Scalars['Void']['output']>;
  /**
   * A mutation to delete a given trusted device.
   * Required headers:
   * * Authorization
   */
  deleteTrustedDevice?: Maybe<Scalars['Void']['output']>;
  /**
   * Deletes an alert for a calling user. The alert doesn't have to be disabled prior to deletion. \
   * \
   * Required headers:
   * * Authorization
   */
  deleteUserFxRateAlert?: Maybe<Scalars['Void']['output']>;
  /**
   * Deletes an alert for a calling user. The alert doesn't have to be disabled prior to deletion. \
   * \
   * Required headers:
   * * Authorization
   */
  deleteUserFxRateAlertV2?: Maybe<Scalars['Void']['output']>;
  /** Mutation that allows user to delete previously registered card by registration id. \ */
  deleteUserRegisteredCard?: Maybe<Scalars['Void']['output']>;
  /**
   * Disables already existing alert for a calling user and returns updated alert. \
   * \
   * This is an idempotent operation. \
   * \
   * Required headers:
   * * Authorization
   */
  disableUserFxRateAlert: UserFxRateAlert;
  /**
   * Disables already existing alert for a calling user and returns updated alert. \
   * \
   * This is an idempotent operation. \
   * \
   * Required headers:
   * * Authorization
   */
  disableUserFxRateAlertV2?: Maybe<Scalars['Void']['output']>;
  /**
   * The mutation to get discounted single product calculation data based on provided calculation id and product id.
   * Note: *Authorization* token must be added to the request. Otherwise the request will be rejected by pre-submission-orchestrator service.
   * \
   * Calls pre-submission-orchestrator underneath. \
   * \
   * Required HTTP headers:
   * * Authorization (authorization token)
   * * X-WR-Platform [Android, Web, iOS]
   * * X-WR-PlatformVersion (Not required for Web, required for iOS and Androids)
   * * X-WR-CorrelationId
   * * X-WR-RequestId
   * * X-WR-TransferId
   * * X-WR-CallerId
   * * Accept-Language (Default value : en)
   * * Content-Type: application/json
   */
  discountProductCalculation: DiscountProductCalculationResult;
  /**
   * Enables already existing alert for a calling user and returns updated alert. \
   * \
   * This is an idempotent operation. \
   * \
   * Required headers:
   * * Authorization
   */
  enableUserFxRateAlert: UserFxRateAlert;
  /**
   * Enables already existing alert for a calling user and returns updated alert. \
   * \
   * This is an idempotent operation. \
   * \
   * Required headers:
   * * Authorization
   */
  enableUserFxRateAlertV2?: Maybe<Scalars['Void']['output']>;
  /**
   * A mutation to initialize apple pay payment session. \
   * \
   * Required headers:
   * * Authorization
   */
  initializeApplePaySession: ApplePaySessionInitialized;
  /**
   * A mutation to initialize FPX payment session. \
   * \
   * Required headers:
   * * Authorization
   */
  initializeFpxSession: FpxSessionInitialized;
  /**
   * Initializes the payment session.
   *
   * Required HTTP headers:
   * * Authorization
   * * X-WR-Platform
   */
  initializePaymentSession: PaymentSessionInitialized;
  /**
   * Initiate a new chat or retrieve an ongoing one
   * Requires headers:
   * Authorization (bearer token)
   */
  initiateChat?: Maybe<InitiateChatResponse>;
  /**
   * A mutation to create a new recipient from given input. Returns either an id of the created recipient or list of
   * validation errors if occurred. \
   *  \
   * Calls legacy API to create the recipient. \
   *  \
   * Required HTTP headers:
   * * Authorization
   * * X-WR-Platform
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  recipientCreate: RecipientCreationResult;
  /**
   * A mutation to delete an existing recipient for given user. Returns either an id of the deleted recipient or list of
   * validation errors if occurred. \
   * \
   * Calls legacy API to update the recipient. \
   * \
   * Required HTTP headers:
   * * Authorization
   * * X-WR-Platform
   */
  recipientDelete: RecipientDeletionResult;
  /**
   * A mutation to update an existing recipient from given input. Returns either an id of the updated recipient or list of
   * validation errors if occurred. \
   * \
   * Calls legacy API to update the recipient. \
   * \
   * Required HTTP headers:
   * * Authorization
   * * X-WR-Platform
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  recipientUpdate: RecipientUpdateResult;
  /**
   * A mutation to refresh an access token. Requires a valid access or refresh token.\
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  refreshAccessToken: RefreshAccessTokenResponse;
  /**
   * Mutation to report a bug. This will also trigger a slack notification
   *
   * Required HTTP headers:
   * * Authorization (bearer token)
   */
  reportBug?: Maybe<Scalars['Void']['output']>;
  /**
   * Mutation to request a feature. This will also trigger a slack notification
   *
   * Required HTTP headers:
   * * Authorization (bearer token)
   */
  requestAFeature?: Maybe<Scalars['Void']['output']>;
  /**
   * A mutation to reset a password - user provides password reset code obtained with `sendPasswordResetLink` mutation
   * and a new password in input. graphql-authentication
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  resetPassword?: Maybe<Scalars['Void']['output']>;
  /**
   * A mutation to request a password reset action. As a result, user gets an e-mail with password reset link/code -
   * code should be used in `resetPassword` mutation. graphql-authentication
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  sendPasswordResetLink?: Maybe<Scalars['Void']['output']>;
  setPreferredBankAccount?: Maybe<Scalars['Void']['output']>;
  /** Sets the user preferred language */
  setUserPreferredLanguage: UserPreferredLanguageSetResult;
  /**
   * A mutation to perform sign in action of a particular user. Requires user's email and password. \
   *  \
   * Returns an access token or MFARequired after successful log in action. \
   *  \
   * Calls authentication service to log in the user.
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  signInV2: SignInV2Response;
  /**
   * A mutation to log out the user In graphql-authentication. \
   * \
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  signOut?: Maybe<Scalars['Void']['output']>;
  /**
   * A mutation to perform sign up action of a new user. Requires user's email, password and a map of other fields. \
   *  \
   * Returns a new user long id and uuid after successful sign up.\
   *  \
   * Calls legacy api to create the user.
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  signUp: SignUpResponse;
  /**
   * A mutation to perform social sign in action of a particular user. Requires an oauth token. \
   * \
   * Returns
   * - an access token after successful log in
   * - MFARequired if Device Authentication is required
   * - RegistrationRequired if there is no social account for this user.
   * \
   * Calls authentication service to log in the user.
   *
   * Marked as @sensitive: May contain sensitive information and should be handled with care.
   */
  socialSignIn: SocialSignInResponse;
  startProcess?: Maybe<Process>;
  /**
   * Mutation to answer questions from Outreach Requests
   *
   * Required HTTP headers:
   * * Authorization (bearer token)
   */
  submitOutreachAnswer?: Maybe<SubmitOutreachAnswerResponse>;
  terminateChat?: Maybe<Scalars['Void']['output']>;
  updateOrderAnnotation: OrderAnnotation;
  uploadOutreachDocument?: Maybe<UploadResponse>;
  validateForm: Scalars['JSON']['output'];
  /** A mutation to validate password reset code for both its existence and expiration date. graphql-authentication */
  validatePasswordResetLink: PasswordResetLinkValidationResult;
};

export type MutationAuthoriseExistingCardPaymentSessionArgs = {
  input: ExistingCardPaymentInput;
};

export type MutationAuthoriseNewCardPaymentSessionArgs = {
  input: NewCardPaymentInput;
};

export type MutationAuthorizeApplePayPaymentSessionArgs = {
  applePayPaymentAuthorizationInput: ApplePayPaymentAuthorizationInput;
};

export type MutationBeginMfaArgs = {
  input: BeginMfaInput;
};

export type MutationCancelTransferArgs = {
  transferId: Scalars['ID']['input'];
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type MutationComplete3DsPaymentSessionArgs = {
  input: ThreeDsInput;
};

export type MutationCompleteMfaArgs = {
  input: CompleteMfaInput;
};

export type MutationCompleteStepArgs = {
  properties?: InputMaybe<Array<InputMaybe<Property>>>;
  stepId: Scalars['ID']['input'];
};

export type MutationCreateAirtimePurchaseArgs = {
  createAirtimePurchaseInput: CreateAirtimePurchaseInput;
};

export type MutationCreateCalculationArgs = {
  calculationInput: CalculationInput;
};

export type MutationCreatePaymentArgs = {
  payload: CreatePaymentInput;
};

export type MutationCreateProductsCalculationArgs = {
  productsCalculationInput: ProductsCalculationInput;
};

export type MutationCreateQuoteArgs = {
  quoteInput: QuoteInput;
};

export type MutationCreateRiskifiedSessionMappingArgs = {
  riskifiedSessionMappingInput: RiskifiedSessionMappingInput;
};

export type MutationCreateThreatMetrixSessionMappingArgs = {
  threatMetrixSessionMappingInput: ThreatMetrixSessionMappingInput;
};

export type MutationCreateUserFxRateAlertArgs = {
  fxRateAlertInput: UserFxRateAlertInput;
};

export type MutationCreateUserFxRateAlertV2Args = {
  fxRateAlertInputV2: UserFxRateAlertInputV2;
};

export type MutationDeleteSavedBankAccountArgs = {
  deleteSavedAccountInput: DeleteSavedAccountInput;
};

export type MutationDeleteSavedCardsArgs = {
  input: DeleteCardInput;
};

export type MutationDeleteTrustedDeviceArgs = {
  deviceId: Scalars['String']['input'];
};

export type MutationDeleteUserFxRateAlertArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUserFxRateAlertV2Args = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUserRegisteredCardArgs = {
  registrationId: Scalars['ID']['input'];
};

export type MutationDisableUserFxRateAlertArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDisableUserFxRateAlertV2Args = {
  id: Scalars['ID']['input'];
};

export type MutationDiscountProductCalculationArgs = {
  discountProductCalculationInput: DiscountProductCalculationInput;
};

export type MutationEnableUserFxRateAlertArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEnableUserFxRateAlertV2Args = {
  id: Scalars['ID']['input'];
};

export type MutationInitializeApplePaySessionArgs = {
  applePaySessionInitializationInput: ApplePaySessionInitializationInput;
};

export type MutationInitializeFpxSessionArgs = {
  fpxSessionInitializationInput: FpxSessionInitializationInput;
};

export type MutationInitializePaymentSessionArgs = {
  input: InitPaymentInput;
};

export type MutationRecipientCreateArgs = {
  recipientCreateInput: RecipientCreateInput;
};

export type MutationRecipientDeleteArgs = {
  recipientId: Scalars['ID']['input'];
};

export type MutationRecipientUpdateArgs = {
  recipientUpdateInput: RecipientUpdateInput;
};

export type MutationRefreshAccessTokenArgs = {
  input: RefreshAccessTokenInput;
};

export type MutationReportBugArgs = {
  reportBugRequest?: InputMaybe<ReportBugRequest>;
};

export type MutationRequestAFeatureArgs = {
  requestAFeatureRequest?: InputMaybe<RequestAFeatureRequest>;
};

export type MutationResetPasswordArgs = {
  payload: ResetPasswordInput;
};

export type MutationSendPasswordResetLinkArgs = {
  payload: ForgotPasswordInput;
};

export type MutationSetPreferredBankAccountArgs = {
  setPreferredAccountInput: SetPreferredAccountInput;
};

export type MutationSetUserPreferredLanguageArgs = {
  preferredLanguageInput: UserPreferredLanguageInput;
};

export type MutationSignInV2Args = {
  credentials: UserCredentialsInput;
};

export type MutationSignUpArgs = {
  signUpRequest: SignUpRequest;
};

export type MutationSocialSignInArgs = {
  credentials: SocialSignInInput;
};

export type MutationStartProcessArgs = {
  name: Scalars['String']['input'];
  properties?: InputMaybe<Array<InputMaybe<Property>>>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSubmitOutreachAnswerArgs = {
  input: SubmitOutreachAnswerRequest;
};

export type MutationUpdateOrderAnnotationArgs = {
  annotation: UpdateOrderAnnotationInput;
};

export type MutationUploadOutreachDocumentArgs = {
  uploadRequest: FileUploadRequest;
};

export type MutationValidateFormArgs = {
  data: ValidationInput;
};

export type MutationValidatePasswordResetLinkArgs = {
  passwordResetLinkValidationInput: PasswordResetLinkValidationInput;
};

/** Input for submitting a payment transaction using a new card or an unsaved card. */
export type NewCardPaymentInput = {
  cvv: Scalars['String']['input'];
  expiryMonth: Scalars['Int']['input'];
  expiryYear: Scalars['Int']['input'];
  paymentSessionId: Scalars['ID']['input'];
  preferredScheme?: InputMaybe<Scalars['String']['input']>;
  saveCard: Scalars['Boolean']['input'];
  /** The tokenized data from token ex. */
  token: TokenizedCardDataInput;
};

export type OrderAnnotation = {
  __typename?: 'OrderAnnotation';
  annotation: Scalars['String']['output'];
  orderId: Scalars['ID']['output'];
};

export type OrderAnnotationSuggestion = {
  __typename?: 'OrderAnnotationSuggestion';
  name?: Maybe<Scalars['String']['output']>;
};

export enum OrderType {
  Legacy = 'Legacy',
  MoneyTransfer = 'MoneyTransfer',
  Product = 'Product',
}

export type OutreachFormTemplateField = {
  __typename?: 'OutreachFormTemplateField';
  actionRequired: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  errors: TemplateFieldErrors;
  header?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  listItems?: Maybe<Array<Maybe<TemplateListItem>>>;
  name: Scalars['String']['output'];
  placeholder?: Maybe<Scalars['String']['output']>;
  templateFieldType: Scalars['String']['output'];
  validation: TemplateFieldValidation;
};

export type OutreachTask = {
  __typename?: 'OutreachTask';
  createdAt: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isBlockedForTransaction: Scalars['Boolean']['output'];
  lodComment: Scalars['String']['output'];
  outreachFormTemplateFields: Array<Maybe<OutreachFormTemplateField>>;
  reasonForRequest: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  zboTaskId: Scalars['String']['output'];
};

export type Page = {
  __typename?: 'Page';
  description?: Maybe<Scalars['Object']['output']>;
  modulesCollection?: Maybe<Modules>;
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** PageInfo */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Field endCursor */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Field hasNextPage */
  hasNextPage: Scalars['Boolean']['output'];
  /** Field hasPreviousPage */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Field startCursor */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum ParticipantRole {
  Agent = 'AGENT',
  Customer = 'CUSTOMER',
  CustomBot = 'CUSTOM_BOT',
  Supervisor = 'SUPERVISOR',
  System = 'SYSTEM',
}

export type PasswordResetLinkValidated = {
  __typename?: 'PasswordResetLinkValidated';
  _?: Maybe<Scalars['Void']['output']>;
};

export type PasswordResetLinkValidationFailed = {
  __typename?: 'PasswordResetLinkValidationFailed';
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export type PasswordResetLinkValidationInput = {
  code: Scalars['String']['input'];
};

export type PasswordResetLinkValidationResult =
  | PasswordResetLinkValidated
  | PasswordResetLinkValidationFailed;

export type PayInBankDetails = {
  __typename?: 'PayInBankDetails';
  accountName: Scalars['String']['output'];
  accountNumber: Scalars['String']['output'];
  bank: Scalars['String']['output'];
  bankReference: Scalars['String']['output'];
  sortCode?: Maybe<Scalars['String']['output']>;
};

export type PayInMethod = {
  __typename?: 'PayInMethod';
  deliveryTime?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  icon?: Maybe<Icon>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  transferRedirectionType?: Maybe<Scalars['String']['output']>;
};

/** Transfer cost calculated for given pay in method. */
export type PayInMethodCalculation = {
  __typename?: 'PayInMethodCalculation';
  discount?: Maybe<Fee>;
  fee: Fee;
  /** @deprecated No longer supported */
  isAvailable: Scalars['Boolean']['output'];
  payInMethod: PayInMethod;
  totalToPay: Money;
};

export type PayOutMethod = {
  __typename?: 'PayOutMethod';
  code?: Maybe<Scalars['String']['output']>;
  correspondents?: Maybe<Array<Maybe<Correspondent>>>;
  currencies?: Maybe<Array<Maybe<Scalars['CurrencyCode']['output']>>>;
  description?: Maybe<Scalars['String']['output']>;
  feeEstimate?: Maybe<Fee>;
  name?: Maybe<Scalars['String']['output']>;
  payOutTimeEstimate?: Maybe<Scalars['String']['output']>;
};

export type PayOutMethodFeeEstimateArgs = {
  sendAmount?: InputMaybe<Scalars['BigDecimal']['input']>;
  sendCurrency: Scalars['String']['input'];
};

export type PayOutMethodsInput = {
  receiveCountry: Scalars['CountryCode']['input'];
  receiveCurrency: Scalars['CurrencyCode']['input'];
  sendCountry: Scalars['CountryCode']['input'];
};

export type PayOutOptions = {
  __typename?: 'PayOutOptions';
  currency?: Maybe<Scalars['CurrencyCode']['output']>;
  hasLimit?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['BigDecimal']['output']>;
  roundingSeed?: Maybe<Scalars['BigDecimal']['output']>;
};

export type PaymentCreated = {
  __typename?: 'PaymentCreated';
  cancelUrl?: Maybe<Scalars['String']['output']>;
  failureUrl?: Maybe<Scalars['String']['output']>;
  paymentMethodImplementationId?: Maybe<Scalars['ID']['output']>;
  paymentSessionId?: Maybe<Scalars['ID']['output']>;
  paymentUrl?: Maybe<Scalars['String']['output']>;
  sessionData?: Maybe<SessionData>;
  successUrl?: Maybe<Scalars['String']['output']>;
  transferId?: Maybe<Scalars['ID']['output']>;
};

/** Card payment failed result. Returned when a payment fails. */
export type PaymentFailedResult = {
  __typename?: 'PaymentFailedResult';
  errorCode: Scalars['String']['output'];
  reasonAdditionalInfo?: Maybe<Scalars['String']['output']>;
};

/** Card payment failed result. Returned when a payment fails. */
export type PaymentPendingResult = {
  __typename?: 'PaymentPendingResult';
  status: Scalars['String']['output'];
};

export type PaymentResult =
  | PaymentFailedResult
  | PaymentPendingResult
  | PaymentResultRequires3Ds
  | PaymentSuccessfulResult;

export enum PaymentResultCode {
  Authorised = 'AUTHORISED',
  Pending = 'PENDING',
}

/** Card payment requires 3ds result. Returned when a payment requires 3ds authentication. */
export type PaymentResultRequires3Ds = {
  __typename?: 'PaymentResultRequires3DS';
  /** The challenge object should be a direct copy of the challenge received from the provider. */
  challenge: ThreeDsChallenge;
  /** Challenge provider is the name of the 3DS provider that is handling the 3DS. */
  challengeProvider?: Maybe<Scalars['String']['output']>;
  /** Configuration object for the 3DS provider. */
  config?: Maybe<ThreeDsConfig>;
  paymentSessionId: Scalars['ID']['output'];
};

export type PaymentSessionInitialized = {
  __typename?: 'PaymentSessionInitialized';
  /**
   * The cancel url to redirect to if the user cancels the payment
   * if the request originates from the web app the url will be
   * https:// based urls. If the request originates from the mobile app
   * the url will be wr-express-app:// based urls.
   */
  cancelUrl: Scalars['String']['output'];
  /** The failure url to redirect to if the payment fails. */
  failureUrl: Scalars['String']['output'];
  paymentSessionId: Scalars['ID']['output'];
  /** The return url to redirect to if the payment is successful. */
  returnUrl: Scalars['String']['output'];
  /** Token ex config to be used for token ex on the client side. */
  tokenExConfig: TokenExConfig;
};

export enum PaymentStatus {
  Done = 'DONE',
  Pending = 'PENDING',
}

/** Card payment successful result. Returned when a payment is successfully completed. */
export type PaymentSuccessfulResult = {
  __typename?: 'PaymentSuccessfulResult';
  /** The id of the payment. */
  paymentId: Scalars['ID']['output'];
  /** The redirect url to redirect to after a successful payment. */
  redirectUrl: Scalars['String']['output'];
};

export enum PendingTaskCode {
  CashPickupInstructions = 'CashPickupInstructions',
  ContactUs = 'ContactUs',
  DisplayBankDetails = 'DisplayBankDetails',
  RecipientUpdate = 'RecipientUpdate',
  UploadId = 'UploadId',
}

/** User's number mark as contact details. */
export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  dialCode?: Maybe<Scalars['String']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  verificationDetails?: Maybe<VerificationDetails>;
};

export type PhoneNumberValidated = {
  __typename?: 'PhoneNumberValidated';
  _?: Maybe<Scalars['Void']['output']>;
};

export type PhoneNumberValidationFailed = {
  __typename?: 'PhoneNumberValidationFailed';
  message: Scalars['String']['output'];
};

export type PhoneNumberValidationInput = {
  /** Marked as @sensitive: May contain sensitive information and should be handled with care. */
  mobileNumber: Scalars['String']['input'];
  receiveCountry: Scalars['CountryCode']['input'];
  sendCountry: Scalars['CountryCode']['input'];
};

export type PhoneNumberValidationResult =
  | PhoneNumberValidated
  | PhoneNumberValidationFailed;

export type Preferences = {
  __typename?: 'Preferences';
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  receiveCountryCode?: Maybe<Scalars['String']['output']>;
};

export type Process = {
  __typename?: 'Process';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nextStep?: Maybe<Step>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export type Product = {
  __typename?: 'Product';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  settlementCost?: Maybe<Scalars['BigDecimal']['output']>;
  settlementCurrency?: Maybe<Scalars['String']['output']>;
  settlementReceiveCost?: Maybe<Scalars['BigDecimal']['output']>;
  settlementReceiveCurrency?: Maybe<Scalars['String']['output']>;
  subserviceName?: Maybe<Scalars['String']['output']>;
};

/**
 * Input that has to be supplied to get an existing single product calculation.
 *
 * Marked as @draft: Subject to breaking changes and removal. Not available in production.
 */
export type ProductCalculationQueryInput = {
  calculationId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};

/** Marked as @draft: Subject to breaking changes and removal. Not available in production. */
export type ProductCalculationResult = {
  __typename?: 'ProductCalculationResult';
  calculation?: Maybe<SingleProductCalculation>;
  errors?: Maybe<Array<Maybe<CreateCalculationError>>>;
};

/** Marked as @draft: Subject to breaking changes and removal. Not available in production. */
export type ProductCalculations = {
  __typename?: 'ProductCalculations';
  payInMethodCalculations?: Maybe<Array<Maybe<ProductPayInMethodCalculation>>>;
  product?: Maybe<Product>;
};

/** Marked as @draft: Subject to breaking changes and removal. Not available in production. */
export type ProductFee = {
  __typename?: 'ProductFee';
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Marked as @draft: Subject to breaking changes and removal. Not available in production. */
export type ProductPayInMethodCalculation = {
  __typename?: 'ProductPayInMethodCalculation';
  discount?: Maybe<Discount>;
  exchangeRate?: Maybe<Scalars['BigDecimal']['output']>;
  fee?: Maybe<ProductFee>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  payInMethod?: Maybe<PayInMethod>;
  totalToPay?: Maybe<TotalToPay>;
};

/**
 * Input that has to be supplied to create a new product calculation.
 *
 * Marked as @draft: Subject to breaking changes and removal. Not available in production.
 */
export type ProductsCalculationInput = {
  correspondentId?: InputMaybe<Scalars['String']['input']>;
  mobileNumber: Scalars['String']['input'];
  payOutMethodCode: Scalars['String']['input'];
  receive: CountryCurrency;
  send: CountryCurrency;
};

/** Marked as @draft: Subject to breaking changes and removal. Not available in production. */
export type ProductsCalculationResult = {
  __typename?: 'ProductsCalculationResult';
  calculation?: Maybe<ProductsCalculations>;
  errors?: Maybe<Array<Maybe<CreateCalculationError>>>;
};

/** Marked as @draft: Subject to breaking changes and removal. Not available in production. */
export type ProductsCalculations = {
  __typename?: 'ProductsCalculations';
  corridor?: Maybe<Corridor>;
  id?: Maybe<Scalars['String']['output']>;
  productsCalculations?: Maybe<Array<Maybe<ProductCalculations>>>;
  topUpAmount?: Maybe<TopUpAmount>;
};

export enum PromoCodeType {
  Promo = 'PROMO',
  Raf = 'RAF',
}

export enum Promotion {
  Coupon = 'COUPON',
  ReferralReward = 'REFERRAL_REWARD',
}

export type Property = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type PushToVisaDetails = {
  __typename?: 'PushToVisaDetails';
  cardNetwork?: Maybe<Scalars['String']['output']>;
  cardNumberLastFourDigits?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  /**
   * A query to return account details for a user - user identity is derived from the *Authorization* token. \
   *  \
   * Calls customer-api to retrieve the details. \
   *  \
   * Required HTTP headers:
   * * Authorization
   */
  accountDetails?: Maybe<AccountDetails>;
  /**
   * A query to return account details with missing fields for a user - user identity is derived from the *Authorization* token. \
   *  \
   *     Integrates with DFM and Legacy to calculate if user has some missing fields and prefills existing ones. \
   *  \
   * Required HTTP headers:
   * * Authorization
   */
  accountDetailsWithMissingFields?: Maybe<AccountDetailsWithMissingFields>;
  /**
   * Requires headers:
   * Authorization (bearer token)
   */
  activeOutreachTask?: Maybe<OutreachTask>;
  /**
   * A query to return available coupons for a user - user identity is derived from the *Authorization* token. \
   * Required HTTP headers:
   * * Authorization
   * * X-WR-Platform
   */
  availableCoupons: Array<AvailableCouponsResult>;
  /**
   * A query to return all available languages for the user. Currently, authentication is not required to perform that action. \
   *  \
   * Calls content-service to provide a list of available languages.
   */
  availableLanguages?: Maybe<Array<Maybe<Language>>>;
  /**
   * Requires headers:
   * Authorization (bearer token)
   */
  chatTranscripts?: Maybe<ChatTranscripts>;
  /** Returns a list of correspondents' locations. */
  correspondentLocations?: Maybe<CorrespondentLocationConnection>;
  /**
   * A query to select all correspondents based on provided send/receive country codes, pay-out method code
   * and receive currency code.
   */
  correspondents: Array<Correspondent>;
  corridorsBySendCountry: Array<Corridor>;
  corridorsBySendCountrySortedByReceiveCountryName: Array<Corridor>;
  currentStep?: Maybe<Step>;
  /**
   * The query to get discounted money calculation data by calculation id and with applying promoCode if given.
   * Note: `promoCode` is optional, however if provided, *Authorization*
   * token must be added to the request. Otherwise the request will be rejected by pre-submission-orchestrator service.
   * \
   * Calls pre-submission-orchestrator underneath. \
   * \
   * Required HTTP headers:
   * * Authorization (only for requests with promoCode)
   * * X-WR-Platform
   */
  discountCalculation: DiscountCalculationResult;
  fake?: Maybe<Scalars['Void']['output']>;
  featureFlag: FeatureFlagState;
  /**
   * Query to get contentful FAQ Item by the entryId
   *
   * Required HTTP headers:
   * * Accept-Language
   */
  fetchPageById?: Maybe<Page>;
  /**
   * Query to get contentful FAQ Item by slug name
   *
   * Required HTTP headers:
   * * Accept-Language
   */
  fetchPageBySlug?: Maybe<Page>;
  /**
   * Requires headers:
   * Authorization (bearer token)
   * @deprecated No longer supported
   */
  fetchTranscriptsByContactId?: Maybe<ChatTranscripts>;
  /** @deprecated Unused */
  getActiveCorridors: ActiveCorridorsResult;
  getAddressesLoqate: Array<AddressesResLoqate>;
  /**
   * The query to get money calculation data by calculation id that has been created by createCalculation mutation.
   * \
   * Calls pre-submission-orchestrator underneath. \
   * \
   * Required HTTP headers:
   * * Authorization (only for requests with promoCode)
   * * X-WR-Platform
   */
  getCalculation: GetCalculationResult;
  getComponent?: Maybe<DynamicComponent>;
  /**
   * A query to return customer compliance status for a user - user identity is derived from the *Authorization* token. \
   * \
   * Required HTTP headers:
   * * Authorization
   */
  getCustomerComplianceStatus: CustomerComplianceResponse;
  getForm: DynamicForm;
  /**
   * A query to get single product calculation based on provided calculation id and product id
   * \
   * Calls pre-submission-orchestrator underneath. \
   * \
   * Required HTTP headers:
   *     * X-WR-Platform [Android, Web, iOS]
   *     * X-WR-PlatformVersion (Not required for Web, required for iOS and Androids)
   *     * X-WR-CorrelationId
   *     * X-WR-RequestId
   *     * X-WR-TransferId
   *     * X-WR-CallerId
   *     * Accept-Language (Default value : en)
   *     * Content-Type: application/json
   */
  getProductCalculation: ProductCalculationResult;
  getSavedBankAccounts: Array<UserSavedBankAccount>;
  health: Scalars['String']['output'];
  /**
   * Requires headers:
   * Authorization (bearer token)
   */
  historicalChats: Array<Chat>;
  isReady: Scalars['Boolean']['output'];
  matchAddressesLoqate: Array<MatchAddressResLoqate>;
  /**
   * A query to return the most recent transfer to a recipient. \
   * \
   * Required HTTP headers:
   * * Authorization
   */
  mostRecentTransferToRecipient: Transfer;
  /**
   * Required HTTP headers:
   * * Authorization
   */
  payInBankDetails: PayInBankDetails;
  /** A query to select all pay-out methods based on provided send/receive country codes and receive currency code. */
  payOutMethods: Array<Maybe<PayOutMethod>>;
  ping?: Maybe<Scalars['String']['output']>;
  popularOrderAnnotations: Array<OrderAnnotationSuggestion>;
  process?: Maybe<Process>;
  processes: Array<Maybe<Process>>;
  /** A query to retrieve transfer receipt. Will return link to file. */
  receiptById: DownloadableReceipt;
  recipientDetails: DynamicForm;
  recipientForm: DynamicForm;
  /**
   * A query to get the referral code associated with the referrer user. \
   * \
   * Query flow:
   * * GET /api/ReferralCodes?senderId=<customerId> from Loyalty-Api
   * * GET /api/account/{senderId} from Sender-Api
   * * GET /api/features/referafriend from Feature-Qualification-Api
   * \
   * Required HTTP headers:
   * * Authorization
   */
  referralCode: ReferralCodeResult;
  /**
   * A query to get the referral scheme for a particular country. \
   *
   * Uses GET api/Countries from Loyalty-Api.
   */
  referralScheme: ReferralSchemeDetails;
  /** Returns card metadata based on specified BIN and country (optional) */
  retrieveCardMetadata: Array<CardMetadata>;
  /**
   * A query that provides list of available send countries. List is sorted by country name. \
   * \
   * Calls legacy API to retrieve the details. \
   * \
   * HTTP headers in use:
   * * X-Forwarded-For
   * * X-Real-IP
   * * X-WR-Request-IP
   */
  sendCountries: Array<Country>;
  /**
   * A query that provides an ordered list of available send countries. List is sorted by the given parameter. \
   * \
   * Calls legacy API to retrieve the details. \
   * \
   * HTTP headers in use:
   * * X-Forwarded-For
   * * X-Real-IP
   * * X-WR-Request-IP
   */
  sendCountriesSorted: Array<Country>;
  senderDetails?: Maybe<DynamicForm>;
  senderForm: DynamicForm;
  submissions: Array<Submission>;
  supportOpeningHours: SupportOpeningHoursResponse;
  /** Returns a tokenEx config. */
  tokenExConfig: TokenExConfiguration;
  /**
   * Retrieves payment status after payment
   *
   * Required HTTP headers:
   * * Authorization
   */
  transactionStatus: PaymentResult;
  transfer?: Maybe<Transfer>;
  /**
   * A query to return full transfer details by its id. \
   * \
   * Calls transaction-api to retrieve the details. \
   * \
   * Required HTTP headers:
   * * Authorization
   * @deprecated transferById query will be replaced by transfer query
   */
  transferById?: Maybe<Transfer>;
  transferConfig: Array<TransferConfigResponse>;
  transferFeatureFlag: FeatureFlagResponse;
  transferRecipientDetails: DynamicForm;
  transferRecipientForm: DynamicForm;
  /**
   * A query to return a list of transfers. \
   * \
   * Required HTTP headers:
   * * Authorization
   */
  transfers: TransferSummaryConnection;
  /**
   * A query to translate multiple keys provided. **Note: clients should check both data and errors in a response as some
   * of keys may be translated correctly while some of them may fail!** \
   *  \
   * Currently, authentication is not required to perform that action. \
   *  \
   * Calls content-service to perform the requested translations.
   */
  translateMany?: Maybe<Array<Maybe<Translation>>>;
  /**
   * A query to translate a single key. Currently, authentication is not required to perform that action. \
   *  \
   * Calls content-service to perform the requested translation.
   */
  translateSingle?: Maybe<Translation>;
  /**
   * A query to retrieve all active trusted devices.
   * Required headers:
   * * Authorization
   */
  trustedDevices: Array<TrustedDevice>;
  /**
   * A query to select user's country - a user id is designated from ip address provided in *X-Real-IP* header (possibly also with *X-Forwarder-From*). \
   * \
   * Calls legacy API to retrieve the details. \
   * \
   * Headers in use:
   * * X-Forwarded-For
   * * X-Real-IP
   * * X-WR-Request-IP
   */
  userCountry: Country;
  /**
   * Returns to user their subscribed alerts, both enabled and disabled. \
   * \
   * Required headers:
   * * Authorization
   */
  userFxRateAlerts: Array<UserFxRateAlert>;
  /**
   * Returns to user their subscribed alerts, both enabled and disabled. \
   * \
   * Required headers:
   * * Authorization
   */
  userFxRateAlertsV2: Array<UserFxRateAlertV2>;
  /**
   * A query to check if user is mfa enabled. Requires userId. \
   * \
   * Returns mfa Type and name, which represents the user's masked phone number\
   * \
   * Calls authentication service to check if user is MFA enabled.
   */
  userMFAFactors: Array<UserMfaFactor>;
  /**
   * A query to retrieve details of a single recipient for a particular user. \
   * \
   * Required HTTP headers:
   * * Authorization
   */
  userRecipient?: Maybe<Recipient>;
  /**
   * A query to retrieve recipients of the current user by country. Supports Relay pagination. \
   * \
   * Required HTTP headers:
   * * Authorization
   */
  userRecipientsByCountry?: Maybe<RecipientConnection>;
  /**
   * A query to retrieve recipients summary of the current user. Supports Relay pagination. \
   * \
   * Required HTTP headers:
   * * Authorization
   */
  userRecipientsSummary?: Maybe<RecipientSummaryConnection>;
  /**
   * A query that provides list of registered card of calling user. \
   * \
   * Required HTTP headers:
   * * Authorization
   */
  userRegisteredCards: Array<RegisteredCard>;
  /**
   * Returns a list of saved cards data for authenticated user.
   *
   * Required HTTP headers:
   * * Authorization
   */
  userSavedCards: Array<SavedCard>;
  /**
   * A query to return all user transfer statuses. Authentication required. \
   * \
   * Calls transfer-status-service to receive details. \
   * \
   * Required HTTP headers:
   * * Authorization
   */
  userTransfers: TransferConnection;
  validatePhoneNumber: PhoneNumberValidationResult;
  validatePhoneNumberLoqate: Array<ValidatePhoneNumberResLoqate>;
  /**
   * A query to return the validity of coupons a user enters at the registration \
   * Required HTTP headers:
   * * X-WR-Platform
   */
  validatePromoCode: ValidatePromoCodeResult;
  /**
   * A query to validate referral code usage for referee from given country \
   * \
   * Query flow:
   * * GET /api/Countries from Loyalty-Api
   * * GET /api/ReferralCodes/{referralCode} from Loyalty-Api
   * * GET /api/account/{senderId} from Sender-Api
   * * GET /api/features/referafriend from Feature-Qualification-Api
   */
  validateReferralCodeUsage: ReferralCodeUsageValidationResult;
  wallet: Wallet;
};

export type QueryAccountDetailsWithMissingFieldsArgs = {
  receiveCountry?: InputMaybe<Scalars['CountryCode']['input']>;
};

export type QueryAvailableCouponsArgs = {
  availableCouponsInput: AvailableCouponsInput;
  filter?: InputMaybe<Promotion>;
};

export type QueryChatTranscriptsArgs = {
  filter: ChatTranscriptsFilterInput;
};

export type QueryCorrespondentLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  correspondentLocationInput: CorrespondentLocationInput;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCorrespondentsArgs = {
  correspondentsInput: CorrespondentsInput;
};

export type QueryCorridorsBySendCountryArgs = {
  sendCountry: Scalars['CountryCode']['input'];
};

export type QueryCorridorsBySendCountrySortedByReceiveCountryNameArgs = {
  sendCountry: Scalars['CountryCode']['input'];
};

export type QueryCurrentStepArgs = {
  processId: Scalars['ID']['input'];
};

export type QueryDiscountCalculationArgs = {
  discountCalculationInput: DiscountCalculationInput;
};

export type QueryFeatureFlagArgs = {
  correspondentId: Scalars['ID']['input'];
  productCode: Scalars['String']['input'];
  receiveCountry: Scalars['CountryCode']['input'];
  sendCountry: Scalars['CountryCode']['input'];
};

export type QueryFetchPageByIdArgs = {
  entryId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFetchPageBySlugArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFetchTranscriptsByContactIdArgs = {
  contactId: Scalars['String']['input'];
};

export type QueryGetActiveCorridorsArgs = {
  corridorsToSearchInput: CorridorsToSearchInput;
};

export type QueryGetAddressesLoqateArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetCalculationArgs = {
  getCalculationInput: GetCalculationInput;
};

export type QueryGetComponentArgs = {
  componentKey: Scalars['String']['input'];
  componentSelections: Array<InterpolationsTuple>;
  correspondentId?: InputMaybe<Scalars['Int']['input']>;
  formName: Scalars['String']['input'];
  productCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['String']['input']>;
  sendCountryCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetCustomerComplianceStatusArgs = {
  request?: InputMaybe<CustomerComplianceRequest>;
};

export type QueryGetFormArgs = {
  correspondentId: Scalars['Int']['input'];
  formName: Scalars['String']['input'];
  productCode: Scalars['String']['input'];
  receiveCountryCode: Scalars['String']['input'];
  sendCountryCode: Scalars['String']['input'];
};

export type QueryGetProductCalculationArgs = {
  productCalculationQueryInput: ProductCalculationQueryInput;
};

export type QueryGetSavedBankAccountsArgs = {
  getSavedAccountsInput: GetSavedAccountsInput;
};

export type QueryMatchAddressesLoqateArgs = {
  req: MatchAddressReqLoqate;
};

export type QueryMostRecentTransferToRecipientArgs = {
  transferToRecipientInput: TransferToRecipientInput;
};

export type QueryPayOutMethodsArgs = {
  payOutMethodsInput: PayOutMethodsInput;
};

export type QueryPingArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProcessArgs = {
  processId: Scalars['ID']['input'];
};

export type QueryProcessesArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryReceiptByIdArgs = {
  transferId: Scalars['ID']['input'];
};

export type QueryRecipientDetailsArgs = {
  correspondentId: Scalars['ID']['input'];
  productCode: Scalars['String']['input'];
  receiveCountry: Scalars['CountryCode']['input'];
  recipientId: Scalars['ID']['input'];
  sendCountry: Scalars['CountryCode']['input'];
};

export type QueryRecipientFormArgs = {
  correspondentId: Scalars['ID']['input'];
  productCode: Scalars['String']['input'];
  receiveCountry: Scalars['CountryCode']['input'];
  sendCountry: Scalars['CountryCode']['input'];
};

export type QueryReferralCodeArgs = {
  referrerId: Scalars['ID']['input'];
};

export type QueryReferralSchemeArgs = {
  country: Scalars['CountryCode']['input'];
};

export type QueryRetrieveCardMetadataArgs = {
  retrieveCardMetadataInput: RetrieveCardMetadataInput;
};

export type QuerySendCountriesSortedArgs = {
  countrySortingAttributes: CountrySortingAttributes;
};

export type QuerySenderDetailsArgs = {
  maskFields?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySenderFormArgs = {
  category?: InputMaybe<FieldCategory>;
  fields?: InputMaybe<Array<Scalars['String']['input']>>;
  receiveCountry?: InputMaybe<Scalars['CountryCode']['input']>;
  sendCountry: Scalars['CountryCode']['input'];
};

export type QuerySubmissionsArgs = {
  formName: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySupportOpeningHoursArgs = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTransactionStatusArgs = {
  paymentSessionId: Scalars['ID']['input'];
};

export type QueryTransferArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTransferByIdArgs = {
  transferId: Scalars['ID']['input'];
};

export type QueryTransferConfigArgs = {
  correspondent?: InputMaybe<Scalars['Int']['input']>;
  payinMethod?: InputMaybe<Scalars['String']['input']>;
  payoutMethod?: InputMaybe<Scalars['String']['input']>;
  receiveAmount?: InputMaybe<Scalars['Float']['input']>;
  receiveCountry?: InputMaybe<Scalars['CountryCode']['input']>;
  receiveCurrency?: InputMaybe<Scalars['String']['input']>;
  sendAmount?: InputMaybe<Scalars['Float']['input']>;
  sendCountry: Scalars['CountryCode']['input'];
  sendCurrency: Scalars['String']['input'];
};

export type QueryTransferFeatureFlagArgs = {
  featureName: Scalars['String']['input'];
  receiveAmount?: InputMaybe<Scalars['Float']['input']>;
  receiveCountry: Scalars['CountryCode']['input'];
  receiveMethod: Scalars['String']['input'];
  sendCountry: Scalars['CountryCode']['input'];
};

export type QueryTransferRecipientDetailsArgs = {
  correspondentId: Scalars['ID']['input'];
  productCode: Scalars['String']['input'];
  receiveCountry: Scalars['CountryCode']['input'];
  sendCountry: Scalars['CountryCode']['input'];
  transactionId: Scalars['ID']['input'];
};

export type QueryTransferRecipientFormArgs = {
  correspondentId: Scalars['ID']['input'];
  productCode: Scalars['String']['input'];
  receiveCountry: Scalars['CountryCode']['input'];
  sendCountry: Scalars['CountryCode']['input'];
};

export type QueryTransfersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TransferFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sorters?: InputMaybe<Array<TransferSortingInput>>;
};

export type QueryTranslateManyArgs = {
  keys: Array<Scalars['String']['input']>;
};

export type QueryTranslateSingleArgs = {
  key: Scalars['String']['input'];
};

export type QueryUserMfaFactorsArgs = {
  input: UserMfaFactorsInput;
};

export type QueryUserRecipientArgs = {
  recipientQueryInput: RecipientQueryInput;
};

export type QueryUserRecipientsByCountryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['CountryCode']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryUserRecipientsSummaryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryUserTransfersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TransferStatusFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryValidatePhoneNumberArgs = {
  phoneNumberValidationInput: PhoneNumberValidationInput;
};

export type QueryValidatePhoneNumberLoqateArgs = {
  phoneNumber: Scalars['String']['input'];
};

export type QueryValidatePromoCodeArgs = {
  validatePromoCodeInput: ValidatePromoCodeInput;
};

export type QueryValidateReferralCodeUsageArgs = {
  validationInput: ReferralCodeUsageValidationInput;
};

export type Quote = {
  __typename?: 'Quote';
  discount?: Maybe<Discount>;
  exchangeRate?: Maybe<FxRate>;
  fee?: Maybe<Money>;
  receive?: Maybe<Money>;
  send?: Maybe<Money>;
  totalToPay?: Maybe<Money>;
};

/**
 * Input that has to be supplied to create a new calculation. Note: `promoCode` is optional, however if provided, *Authorization*
 * token must be added to the request. Otherwise the request will be rejected by pricing-facade service.
 */
export type QuoteInput = {
  amount: Scalars['BigDecimal']['input'];
  partnerCode?: InputMaybe<Scalars['String']['input']>;
  payOutMethodCode?: InputMaybe<Scalars['String']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  receive: CountryCurrencyPair;
  send: CountryCurrencyPair;
  serviceProvider?: InputMaybe<Scalars['String']['input']>;
  type: CalculationType;
};

export type QuoteSummary = {
  __typename?: 'QuoteSummary';
  receive?: Maybe<Money>;
  send?: Maybe<Money>;
  totalToPay?: Maybe<Money>;
};

/** Marked as @draft: Subject to breaking changes and removal. Not available in production. */
export type ReceiveCountry = {
  country: Scalars['String']['input'];
};

export type Recipient = {
  __typename?: 'Recipient';
  address?: Maybe<Address>;
  card?: Maybe<Card>;
  contactData?: Maybe<ContactData>;
  /** @deprecated Mobile app should use recipientDetails query for form fields */
  formFields?: Maybe<Array<Maybe<RecipientFormField>>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Recipient Connection */
export type RecipientConnection = {
  __typename?: 'RecipientConnection';
  /** Field edges */
  edges?: Maybe<Array<Maybe<RecipientEdge>>>;
  /** Field pageInfo */
  pageInfo?: Maybe<PageInfo>;
};

/** Detailed information filled when country is a receive country - transfer can be sent to it */
export type RecipientCountryDetails = {
  __typename?: 'RecipientCountryDetails';
  currencies: Array<Scalars['CurrencyCode']['output']>;
  /** True if country is a receive country */
  isReceiveCountry: Scalars['Boolean']['output'];
};

/**
 * Recipient input data needed to create a new recipient. Product code, receive country code, correspondent id are required,
 * calculation is optional.
 */
export type RecipientCreateInput = {
  calculationId?: InputMaybe<Scalars['ID']['input']>;
  correspondentId: Scalars['ID']['input'];
  /** Marked as @sensitive: May contain sensitive information and should be handled with care. */
  formFields: Array<FormField>;
  productCode: Scalars['String']['input'];
  receiveCountry: Scalars['CountryCode']['input'];
};

/** An id of created recipient, the result is present only on successful creation. */
export type RecipientCreated = {
  __typename?: 'RecipientCreated';
  id: Scalars['ID']['output'];
};

/** Contains an array of all validation errors occurred during recipient creation. */
export type RecipientCreationFailed = {
  __typename?: 'RecipientCreationFailed';
  validationErrors: Array<RecipientValidationError>;
};

export type RecipientCreationResult =
  | RecipientCreated
  | RecipientCreationFailed;

/** An id of deleted recipient, the result is present only on successful deletion. */
export type RecipientDeleted = {
  __typename?: 'RecipientDeleted';
  id: Scalars['ID']['output'];
};

/** Contains an array of all validation errors occurred during recipient deletion. */
export type RecipientDeletionFailed = {
  __typename?: 'RecipientDeletionFailed';
  validationErrors: Array<RecipientValidationError>;
};

export type RecipientDeletionResult =
  | RecipientDeleted
  | RecipientDeletionFailed;

/** Recipient Edge */
export type RecipientEdge = {
  __typename?: 'RecipientEdge';
  /** Field cursor */
  cursor?: Maybe<Scalars['String']['output']>;
  /** Field node */
  node?: Maybe<Recipient>;
};

export type RecipientFormField = {
  __typename?: 'RecipientFormField';
  id?: Maybe<Scalars['ID']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Recipient query input data needed to get single user recipient. Only recipient id is mandatory. */
export type RecipientQueryInput = {
  correspondentId?: InputMaybe<Scalars['ID']['input']>;
  productCode?: InputMaybe<Scalars['String']['input']>;
  receiveCountryCode?: InputMaybe<Scalars['CountryCode']['input']>;
  recipientId: Scalars['ID']['input'];
  sendCountryCode?: InputMaybe<Scalars['CountryCode']['input']>;
};

export type RecipientSnapshot = {
  __typename?: 'RecipientSnapshot';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  mobileMoneyNumber?: Maybe<Scalars['String']['output']>;
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
  recipientId?: Maybe<Scalars['Int']['output']>;
};

export type RecipientSummary = {
  __typename?: 'RecipientSummary';
  card?: Maybe<Card>;
  country: Scalars['CountryCode']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  recipientId?: Maybe<Scalars['Int']['output']>;
};

/** RecipientSummary Connection */
export type RecipientSummaryConnection = {
  __typename?: 'RecipientSummaryConnection';
  /** Field edges */
  edges?: Maybe<Array<Maybe<RecipientSummaryEdge>>>;
  /** Field pageInfo */
  pageInfo?: Maybe<PageInfo>;
};

/** RecipientSummary Edge */
export type RecipientSummaryEdge = {
  __typename?: 'RecipientSummaryEdge';
  /** Field cursor */
  cursor?: Maybe<Scalars['String']['output']>;
  /** Field node */
  node?: Maybe<RecipientSummary>;
};

/** Contains an array of all validation errors occurred during recipient update. */
export type RecipientUpdateFailed = {
  __typename?: 'RecipientUpdateFailed';
  validationErrors: Array<RecipientValidationError>;
};

/** Recipient input data needed to update existing recipient. */
export type RecipientUpdateInput = {
  correspondentId: Scalars['ID']['input'];
  /** Marked as @sensitive: May contain sensitive information and should be handled with care. */
  formFields: Array<FormField>;
  productCode: Scalars['String']['input'];
  recipientId: Scalars['ID']['input'];
};

export type RecipientUpdateResult = RecipientUpdateFailed | RecipientUpdated;

/** An id of updated recipient, the result is present only on successful update. */
export type RecipientUpdated = {
  __typename?: 'RecipientUpdated';
  id: Scalars['ID']['output'];
};

/** Validation error for a single field. */
export type RecipientValidationError = {
  __typename?: 'RecipientValidationError';
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum ReferAFriendValidationError {
  NoPaidTransfers = 'NO_PAID_TRANSFERS',
  RafNotEnabled = 'RAF_NOT_ENABLED',
  RefereeCountryNotSupported = 'REFEREE_COUNTRY_NOT_SUPPORTED',
  ReferralCodeNotFound = 'REFERRAL_CODE_NOT_FOUND',
  ReferrerCountryNotSupported = 'REFERRER_COUNTRY_NOT_SUPPORTED',
}

export type ReferAFriendValidationFailed = {
  __typename?: 'ReferAFriendValidationFailed';
  validationErrors: Array<ReferAFriendValidationError>;
};

export type Referral = {
  __typename?: 'Referral';
  createdDate: Scalars['DateTime']['output'];
  referrerId: Scalars['ID']['output'];
};

export type ReferralCodeDetails = {
  __typename?: 'ReferralCodeDetails';
  code: Scalars['String']['output'];
  referrerFirstName: Scalars['String']['output'];
  referrerId: Scalars['ID']['output'];
};

export type ReferralCodeResult =
  | ReferAFriendValidationFailed
  | ReferralCodeDetails;

export type ReferralCodeUsageValidated = {
  __typename?: 'ReferralCodeUsageValidated';
  code: Scalars['String']['output'];
  refereeCountry: Scalars['CountryCode']['output'];
  referrerFirstName: Scalars['String']['output'];
  referrerId: Scalars['ID']['output'];
};

export type ReferralCodeUsageValidationInput = {
  code: Scalars['String']['input'];
  refereeCountry: Scalars['CountryCode']['input'];
};

export type ReferralCodeUsageValidationResult =
  | ReferAFriendValidationFailed
  | ReferralCodeUsageValidated;

export type ReferralQualifyingConditions = {
  __typename?: 'ReferralQualifyingConditions';
  minimalTransfer?: Maybe<Money>;
};

export type ReferralSchemeDetails = {
  __typename?: 'ReferralSchemeDetails';
  countryCode?: Maybe<Scalars['CountryCode']['output']>;
  qualifyingConditions?: Maybe<ReferralQualifyingConditions>;
  reward?: Maybe<Money>;
  rewardForReferee?: Maybe<Money>;
};

export type RefreshAccessTokenInput = {
  token: Scalars['String']['input'];
};

export type RefreshAccessTokenResponse = {
  __typename?: 'RefreshAccessTokenResponse';
  accessToken: Scalars['String']['output'];
};

export type RegisteredCard = {
  __typename?: 'RegisteredCard';
  cardId: Scalars['ID']['output'];
  displayData: RegisteredCardDisplayData;
  registrationId: Scalars['ID']['output'];
};

export type RegisteredCardDisplayData = {
  __typename?: 'RegisteredCardDisplayData';
  cardType: Scalars['String']['output'];
  expirationDate: Scalars['String']['output'];
  maskedCardNumber: Scalars['String']['output'];
};

export type RegistrationRequiredResponse = {
  __typename?: 'RegistrationRequiredResponse';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type ReportBugRequest = {
  reportContent?: InputMaybe<Scalars['String']['input']>;
  /** Technical identifier of a report. Is generated by the client and used to ensure idempotency on submitting a report. */
  reportId: Scalars['ID']['input'];
};

export type RequestAFeatureRequest = {
  reportContent?: InputMaybe<Scalars['String']['input']>;
  /** Technical identifier of a report. Is generated by the client and used to ensure idempotency on submitting a report. */
  reportId: Scalars['ID']['input'];
};

export type ResetPasswordInput = {
  code: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type RetrieveCardMetadataInput = {
  bin: Scalars['String']['input'];
  sendCountry: Scalars['CountryCode']['input'];
};

export type RiskifiedSessionMappingInput = {
  sendCountryCode: Scalars['CountryCode']['input'];
  transferId: Scalars['ID']['input'];
  userToken: Scalars['String']['input'];
};

/** Contains information about rounding seed of send and receive amounts. */
export type Rounding = {
  __typename?: 'Rounding';
  receiveRoundingSeed?: Maybe<Scalars['BigDecimal']['output']>;
  sendRoundingSeed?: Maybe<Scalars['BigDecimal']['output']>;
};

export type SavedCard = {
  __typename?: 'SavedCard';
  bin?: Maybe<Scalars['String']['output']>;
  expiry?: Maybe<CardExpiry>;
  holderName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  panSuffix?: Maybe<Scalars['String']['output']>;
  scheme?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum ScheduledAlertFrequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type ScheduledRateAlert = UserFxRateAlertV2 & {
  __typename?: 'ScheduledRateAlert';
  alertFrequency: ScheduledAlertFrequency;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  payoutMethod: PayOutMethod;
  receiveCountryCode: Scalars['CountryCode']['output'];
  receiveCurrencyCode: Scalars['CurrencyCode']['output'];
};

/** Detailed information filled when country is a send country - transfer can be sent from it */
export type SenderCountryDetails = {
  __typename?: 'SenderCountryDetails';
  currencies: Array<Scalars['CurrencyCode']['output']>;
  /** True if country is a send country */
  isSendCountry: Scalars['Boolean']['output'];
};

export type SessionData = AdyenSessionData;

export type SetPreferredAccountInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethodId: Scalars['String']['input'];
};

export type SignInSuccessResponse = {
  __typename?: 'SignInSuccessResponse';
  accessToken: Scalars['String']['output'];
};

export type SignInV2Response =
  | AccountDisabledResponse
  | AccountLockedResponse
  | InvalidCredentialsResponse
  | MfaRequiredResponse
  | SignInSuccessResponse;

export type SignUpRequest = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  user?: InputMaybe<Array<InputMaybe<MapEntry>>>;
};

export type SignUpResponse =
  | AccountAlreadyExistsResponse
  | InvalidCredentialsResponse
  | SignUpSuccessResponse;

export type SignUpSuccessResponse = {
  __typename?: 'SignUpSuccessResponse';
  legacyId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type SimplePayOutMethod = {
  __typename?: 'SimplePayOutMethod';
  code?: Maybe<Scalars['String']['output']>;
};

/** Marked as @draft: Subject to breaking changes and removal. Not available in production. */
export type SingleProductCalculation = {
  __typename?: 'SingleProductCalculation';
  corridor?: Maybe<Corridor>;
  id?: Maybe<Scalars['ID']['output']>;
  payInMethodCalculations?: Maybe<Array<Maybe<ProductPayInMethodCalculation>>>;
  product?: Maybe<Product>;
  topUpAmount?: Maybe<TopUpAmount>;
};

export type SocialSignInInput = {
  socialToken: Scalars['String']['input'];
};

export type SocialSignInResponse =
  | AccountDisabledResponse
  | AccountLockedResponse
  | InvalidCredentialsResponse
  | MfaRequiredResponse
  | RegistrationRequiredResponse
  | SignInSuccessResponse;

export enum SortingOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Step = {
  __typename?: 'Step';
  fields: Array<Maybe<Field>>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  processId: Scalars['ID']['output'];
};

export type StepCompletionResult = {
  __typename?: 'StepCompletionResult';
  fields: Array<Maybe<Field>>;
  nextStep?: Maybe<Step>;
  status: Scalars['String']['output'];
};

export type Submission = {
  __typename?: 'Submission';
  _id: Scalars['String']['output'];
  data: Scalars['JSON']['output'];
};

export type SubmitOutreachAnswerRequest = {
  questionAnswer: Scalars['String']['input'];
  questionName: Scalars['String']['input'];
  zboTaskId: Scalars['String']['input'];
};

export type SubmitOutreachAnswerResponse = {
  __typename?: 'SubmitOutreachAnswerResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SupportOpeningHoursResponse = {
  __typename?: 'SupportOpeningHoursResponse';
  /** Chat opening hours */
  chat: Scalars['String']['output'];
  /** Phone opening hours */
  phone: Scalars['String']['output'];
};

export type TargetRateAlert = UserFxRateAlertV2 & {
  __typename?: 'TargetRateAlert';
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  payoutMethod: PayOutMethod;
  receiveCountryCode: Scalars['CountryCode']['output'];
  receiveCurrencyCode: Scalars['CurrencyCode']['output'];
  targetRate: Scalars['Float']['output'];
};

export type TemplateFieldErrors = {
  __typename?: 'TemplateFieldErrors';
  acceptedFiles?: Maybe<Scalars['String']['output']>;
  maxLength?: Maybe<Scalars['String']['output']>;
  minLength?: Maybe<Scalars['String']['output']>;
};

export type TemplateFieldValidation = {
  __typename?: 'TemplateFieldValidation';
  acceptedFiles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  maxLength?: Maybe<Scalars['Int']['output']>;
  minLength?: Maybe<Scalars['Int']['output']>;
};

export type TemplateListItem = {
  __typename?: 'TemplateListItem';
  asset?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ThreatMetrixSessionMappingInput = {
  threatMetrixSessionId: Scalars['String']['input'];
  transferId: Scalars['ID']['input'];
};

export type ThreeDsChallenge = {
  __typename?: 'ThreeDSChallenge';
  action: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** Currently only using Adyen for 3DS config adding union type for future additional types. */
export type ThreeDsConfig = AdyenConfig;

/** Input for submitting a 3ds authentication result. */
export type ThreeDsInput = {
  challenge: ThreeDSecureChallengeParametersInput;
  paymentSessionId: Scalars['ID']['input'];
};

/** The parameters to include in a 3D Secure authentication request. */
export type ThreeDSecureChallengeParametersInput = {
  /** The challenge type (e.g. "01" for a text challenge, "02" for an OTP challenge). */
  challengeType: Scalars['String']['input'];
  /** The merchant name as it should appear on the authentication page. */
  merchantName: Scalars['String']['input'];
  /** The authentication session ID provided by the issuer. */
  threeDSecureId: Scalars['ID']['input'];
};

/** The token ex config is used to configure the token ex service on the client side. */
export type TokenExConfig = {
  __typename?: 'TokenExConfig';
  /** The token ex scheme to use, generally this should be set to 'PCI' - required. */
  scheme: Scalars['String']['output'];
  /** Time stamp generated by the backend for TokenEx HMAC signature. */
  timestamp: Scalars['String']['output'];
  /**
   * Token ex id - required
   * !! DO NOT PERSIST / STORE / LOG this value. !!
   */
  tokenExId: Scalars['ID']['output'];
  /**
   * Token ex key - required
   * !! DO NOT PERSIST / STORE / LOG this value. !!
   */
  tokenExKey: Scalars['String']['output'];
  /** Url for token ex service - required. */
  url: Scalars['String']['output'];
};

/** The token ex config is used to configure the token ex service on the client side. */
export type TokenExConfiguration = {
  __typename?: 'TokenExConfiguration';
  /** The token ex scheme to use, generally this should be set to 'PCI' - required. */
  scheme: Scalars['String']['output'];
  /** Time stamp generated by the backend for TokenEx HMAC signature. */
  timestamp: Scalars['String']['output'];
  /**
   * Token ex id - required
   * !! DO NOT PERSIST / STORE / LOG this value. !!
   */
  tokenExId: Scalars['ID']['output'];
  /**
   * Token ex key - required
   * !! DO NOT PERSIST / STORE / LOG this value. !!
   */
  tokenExKey: Scalars['String']['output'];
  /** Url for token ex service - required. */
  url: Scalars['String']['output'];
};

/** Tokenized card data input used in payment authorization with new card. */
export type TokenizedCardDataInput = {
  firstSix?: InputMaybe<Scalars['String']['input']>;
  lastFour?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  tokenHMAC?: InputMaybe<Scalars['String']['input']>;
};

export type TopUpAmount = {
  __typename?: 'TopUpAmount';
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
};

export type TotalToPay = {
  __typename?: 'TotalToPay';
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
};

export enum TransactionSortableField {
  CreationDate = 'CreationDate',
}

export type TransactionTask = {
  __typename?: 'TransactionTask';
  code?: Maybe<PendingTaskCode>;
  /**  mapped in BFF to constants */
  name?: Maybe<Scalars['String']['output']>;
};

export type Transcript = {
  __typename?: 'Transcript';
  content?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  direction?: Maybe<Direction>;
  participantId?: Maybe<Scalars['String']['output']>;
  participantRole?: Maybe<ParticipantRole>;
  time?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<TranscriptType>;
};

export enum TranscriptType {
  Attachment = 'ATTACHMENT',
  ChatEnded = 'CHAT_ENDED',
  ConnectionAck = 'CONNECTION_ACK',
  Event = 'EVENT',
  Message = 'MESSAGE',
  MessageDelivered = 'MESSAGE_DELIVERED',
  MessageRead = 'MESSAGE_READ',
  ParticipantJoined = 'PARTICIPANT_JOINED',
  ParticipantLeft = 'PARTICIPANT_LEFT',
  TransferFailed = 'TRANSFER_FAILED',
  TransferSucceeded = 'TRANSFER_SUCCEEDED',
  Typing = 'TYPING',
}

export type Transfer = {
  __typename?: 'Transfer';
  /** An alternative identifier which is suitable for use with e.g. Google Analytics integrations. */
  analyticsId?: Maybe<Scalars['String']['output']>;
  annotation?: Maybe<OrderAnnotation>;
  canSendAgain?: Maybe<Scalars['Boolean']['output']>;
  cancellationDetails?: Maybe<CancellationDetails>;
  correspondent?: Maybe<Correspondent>;
  correspondentId?: Maybe<Scalars['ID']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  deliveryDetails?: Maybe<DeliveryDetails>;
  /** @deprecated detailedStatus will be replaced by dynamicTransferStatus field which will return additional statuses and ui information */
  detailedStatus?: Maybe<DetailedTransferStatus>;
  detailedTimeline?: Maybe<DetailedTransferTimeline>;
  dynamicBanner?: Maybe<DynamicBanner>;
  dynamicTransferStatus?: Maybe<DynamicTransferStatus>;
  /** Technical identifier of a transfer, suitable for use in WR GraphQL API. */
  id?: Maybe<Scalars['ID']['output']>;
  isActionRequired: Scalars['Boolean']['output'];
  isKycVerificationRequired: Scalars['Boolean']['output'];
  isReceiptAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated isRecipientChangeAvailable will be replaced completely by isRecipientUpdateAvailable field which will return a boolean value instead of string */
  isRecipientChangeAvailable?: Maybe<Scalars['String']['output']>;
  isRecipientUpdateAvailable?: Maybe<Scalars['Boolean']['output']>;
  orderType?: Maybe<OrderType>;
  payInMethodId?: Maybe<Scalars['String']['output']>;
  payOutMethod?: Maybe<SimplePayOutMethod>;
  pendingTask?: Maybe<TransactionTask>;
  productName?: Maybe<Scalars['String']['output']>;
  quote?: Maybe<Quote>;
  receipt?: Maybe<DownloadableReceipt>;
  receiveCountry?: Maybe<Country>;
  receiveCurrency?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<RecipientSnapshot>;
  /** User-friendly, displayable reference of a transfer. */
  reference?: Maybe<Scalars['String']['output']>;
  sendCountry?: Maybe<Country>;
  sendCurrency?: Maybe<Scalars['String']['output']>;
  /** @deprecated status will be replaced by detailedStatus field which will return additional status code enum i.e. Pending */
  status?: Maybe<TransferStatus>;
  /** @deprecated timeline will be replaced completely by detailedTimeline field which will return more detailed and translated content */
  timeline?: Maybe<TransferTimeline>;
};

export type TransferCancellationFailure = {
  __typename?: 'TransferCancellationFailure';
  reason: Scalars['String']['output'];
};

export type TransferCancellationResult =
  | TransferCancellationFailure
  | TransferCancellationSuccess;

export type TransferCancellationSuccess = {
  __typename?: 'TransferCancellationSuccess';
  id: Scalars['ID']['output'];
};

export type TransferComplianceCheckResult = {
  __typename?: 'TransferComplianceCheckResult';
  status: TransferComplianceCheckStatus;
};

export enum TransferComplianceCheckStatus {
  Compliant = 'Compliant',
  NotCompliant = 'NotCompliant',
}

export type TransferComplianceMoney = {
  amount: Scalars['Float']['input'];
  currencyCode: Scalars['String']['input'];
};

export type TransferConfigResponse = {
  __typename?: 'TransferConfigResponse';
  flagName: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** Transfer Connection */
export type TransferConnection = {
  __typename?: 'TransferConnection';
  /** Field edges */
  edges?: Maybe<Array<Maybe<TransferEdge>>>;
  /** Field pageInfo */
  pageInfo: PageInfo;
};

/** Transfer Edge */
export type TransferEdge = {
  __typename?: 'TransferEdge';
  /** Field cursor */
  cursor?: Maybe<Scalars['String']['output']>;
  /** Field node */
  node?: Maybe<Transfer>;
};

export type TransferFilterInput = {
  recipientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  statuses?: InputMaybe<Array<TransferStatusFilter>>;
};

export enum TransferSortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type TransferSortingInput = {
  field: TransactionSortableField;
  order: TransferSortOrder;
};

export type TransferStatus = {
  __typename?: 'TransferStatus';
  code?: Maybe<TransferStatusCode>;
};

export enum TransferStatusCode {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Failed = 'Failed',
  InProgress = 'InProgress',
  PendingTask = 'PendingTask',
}

export enum TransferStatusFilter {
  Finished = 'Finished',
  Unfinished = 'Unfinished',
}

export type TransferSummary = {
  __typename?: 'TransferSummary';
  /** An alternative identifier which is suitable for use with e.g. Google Analytics integrations. */
  analyticsId?: Maybe<Scalars['String']['output']>;
  annotation?: Maybe<OrderAnnotation>;
  canSendAgain?: Maybe<Scalars['Boolean']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  dynamicTransferStatus?: Maybe<DynamicTransferStatus>;
  /** Technical identifier of a transfer, suitable for use in WR GraphQL API. */
  id?: Maybe<Scalars['ID']['output']>;
  isActionRequired: Scalars['Boolean']['output'];
  isKycVerificationRequired: Scalars['Boolean']['output'];
  orderType?: Maybe<OrderType>;
  payInMethodId?: Maybe<Scalars['String']['output']>;
  payOutMethod?: Maybe<SimplePayOutMethod>;
  pendingTask?: Maybe<TransactionTask>;
  productName?: Maybe<Scalars['String']['output']>;
  quote?: Maybe<QuoteSummary>;
  receiveCountry?: Maybe<CountrySummary>;
  recipient?: Maybe<RecipientSummary>;
  /** User-friendly, displayable reference of a transfer. */
  reference?: Maybe<Scalars['String']['output']>;
  sendCountry?: Maybe<CountrySummary>;
};

/** TransferSummary Connection */
export type TransferSummaryConnection = {
  __typename?: 'TransferSummaryConnection';
  /** Field edges */
  edges?: Maybe<Array<Maybe<TransferSummaryEdge>>>;
  /** Field pageInfo */
  pageInfo: PageInfo;
};

/** TransferSummary Edge */
export type TransferSummaryEdge = {
  __typename?: 'TransferSummaryEdge';
  /** Field cursor */
  cursor?: Maybe<Scalars['String']['output']>;
  /** Field node */
  node?: Maybe<TransferSummary>;
};

/** Representation of flow of a particular transfer. Contains transfer's states and time when they occurred. */
export type TransferTimeline = {
  __typename?: 'TransferTimeline';
  events: Array<TransferTimelineEvent>;
  /** An ordered list of completed, current and expected events. */
  status: TransferTimelineStatus;
};

/** Represents a timeline event. */
export type TransferTimelineEvent = {
  __typename?: 'TransferTimelineEvent';
  /** A timestamp associated with completed and current events. */
  date?: Maybe<Scalars['DateTime']['output']>;
  /** State of the event. */
  state: TransferTimelineEventState;
  /** Status represented by the event. */
  status: TransferTimelineEventStatus;
};

/** Possible states in which each transfer timeline event (and associated transfer step) can be. */
export enum TransferTimelineEventState {
  /** The transfer step was already completed. */
  Completed = 'Completed',
  /** The transfer step that is currently active. */
  Current = 'Current',
  /**
   * The transfer step is neither current nor completed. There is no guarantee that this event will actually occur, e.g.
   * due to cancellation or failure.
   */
  NotCompleted = 'NotCompleted',
}

/** High-level event statuses, which can be represented by a transfer timeline event. */
export enum TransferTimelineEventStatus {
  Cancelled = 'Cancelled',
  Created = 'Created',
  Delivered = 'Delivered',
  Failed = 'Failed',
  InProgress = 'InProgress',
  WithPartners = 'WithPartners',
}

/** Represent overall status of transfer timeline. */
export enum TransferTimelineStatus {
  ActionRequired = 'ActionRequired',
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Failed = 'Failed',
  InProgress = 'InProgress',
}

export type TransferToRecipientInput = {
  recipientId: Scalars['ID']['input'];
};

/** Translation result - contains original key, translated value and language code used during translation. */
export type Translation = {
  __typename?: 'Translation';
  key?: Maybe<Scalars['String']['output']>;
  langCode?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type TrustedDevice = {
  __typename?: 'TrustedDevice';
  id: Scalars['String']['output'];
  lastLoginDate?: Maybe<Scalars['String']['output']>;
  lastLoginLocation?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type UpdateOrderAnnotationInput = {
  annotation: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};

/** Recipient input data needed to update existing recipient for a specific transfer. */
export type UpdateTransferRecipientInput = {
  /** Marked as @sensitive: May contain sensitive information and should be handled with care. */
  formFields: Array<FormField>;
  transactionId: Scalars['ID']['input'];
};

export type UploadResponse = {
  __typename?: 'UploadResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UserCredentialsInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type UserFxRateAlert = {
  __typename?: 'UserFxRateAlert';
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  payoutMethod: PayOutMethod;
  receiveCountryCode: Scalars['CountryCode']['output'];
  receiveCurrencyCode: Scalars['CurrencyCode']['output'];
};

export type UserFxRateAlertInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  payOutMethodCode: Scalars['String']['input'];
  receiveCountryCode: Scalars['CountryCode']['input'];
  receiveCurrencyCode: Scalars['CurrencyCode']['input'];
};

export type UserFxRateAlertInputV2 = {
  alertFrequency?: InputMaybe<ScheduledAlertFrequency>;
  payOutMethodCode: Scalars['String']['input'];
  receiveCountryCode: Scalars['CountryCode']['input'];
  receiveCurrencyCode: Scalars['CurrencyCode']['input'];
  targetRate?: InputMaybe<Scalars['Float']['input']>;
};

export type UserFxRateAlertV2 = {
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  payoutMethod: PayOutMethod;
  receiveCountryCode: Scalars['CountryCode']['output'];
  receiveCurrencyCode: Scalars['CurrencyCode']['output'];
};

export type UserFxRateAlertValidationError = {
  __typename?: 'UserFxRateAlertValidationError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type UserMfaFactor = {
  __typename?: 'UserMFAFactor';
  name: Scalars['String']['output'];
  type: MfaFactorType;
};

export type UserMfaFactorsInput = {
  userId: Scalars['String']['input'];
};

export type UserPreferredLanguageInput = {
  preferredLanguage: Scalars['String']['input'];
};

export type UserPreferredLanguageSetFailure = {
  __typename?: 'UserPreferredLanguageSetFailure';
  account: AccountDetails;
  reason: UserPreferredLanguageSetFailureReason;
};

export enum UserPreferredLanguageSetFailureReason {
  AuthenticationError = 'AUTHENTICATION_ERROR',
  GuestNotPermitted = 'GUEST_NOT_PERMITTED',
  InternalError = 'INTERNAL_ERROR',
  InvalidLanguage = 'INVALID_LANGUAGE',
  PermissionDenied = 'PERMISSION_DENIED',
  UserNotFound = 'USER_NOT_FOUND',
}

export type UserPreferredLanguageSetResult =
  | UserPreferredLanguageSetFailure
  | UserPreferredLanguageSetSuccess;

export type UserPreferredLanguageSetSuccess = {
  __typename?: 'UserPreferredLanguageSetSuccess';
  account: AccountDetails;
};

export type UserSavedBankAccount = {
  __typename?: 'UserSavedBankAccount';
  bankName?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPreferred?: Maybe<Scalars['Boolean']['output']>;
  lastDigits: Scalars['String']['output'];
};

export type UserToken = {
  __typename?: 'UserToken';
  accessToken: Scalars['String']['output'];
};

export type ValidatePhoneNumberResLoqate = {
  __typename?: 'ValidatePhoneNumberResLoqate';
  CountryPrefix?: Maybe<Scalars['Int']['output']>;
  IsValid?: Maybe<Scalars['String']['output']>;
  NationalFormat?: Maybe<Scalars['String']['output']>;
  NetworkCode?: Maybe<Scalars['String']['output']>;
  NetworkCountry?: Maybe<Scalars['String']['output']>;
  NetworkName?: Maybe<Scalars['String']['output']>;
  NumberType?: Maybe<Scalars['String']['output']>;
  PhoneNumber?: Maybe<Scalars['String']['output']>;
  RequestProcessed?: Maybe<Scalars['Boolean']['output']>;
};

export type ValidatePromoCodeInput = {
  promoCode: Scalars['String']['input'];
};

/** Validity and the type of the coupon the user entered. */
export type ValidatePromoCodeResult = {
  __typename?: 'ValidatePromoCodeResult';
  isValid: Scalars['Boolean']['output'];
  type: PromoCodeType;
};

/**
 * An error returned in case of failed business validation of data provided by a user. \
 *  \
 * In practice, if pricing-facade returns 422 client error, its body is deserialized and mapped into this error.
 */
export type ValidationCalculationError = CalculationError & {
  __typename?: 'ValidationCalculationError';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  message: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ValidationInput = {
  context: Context;
  data: Scalars['JSON']['input'];
  formName: Scalars['String']['input'];
};

export type VerificationDetails = {
  __typename?: 'VerificationDetails';
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<VerificationStatus>;
};

export enum VerificationStatus {
  Completed = 'COMPLETED',
  EnforcedDeferred = 'ENFORCED_DEFERRED',
  EnforcedImmediate = 'ENFORCED_IMMEDIATE',
  Suggested = 'SUGGESTED',
  Unspecified = 'UNSPECIFIED',
}

export type Wallet = {
  __typename?: 'Wallet';
  balance: Money;
  id: Scalars['ID']['output'];
  limits: WalletLimits;
  userId: Scalars['ID']['output'];
};

export type WalletLimit = {
  __typename?: 'WalletLimit';
  current: Money;
  max: Money;
  min: Money;
};

export type WalletLimits = {
  __typename?: 'WalletLimits';
  daily: WalletLimit;
  maxBalance: Money;
  monthly: WalletLimit;
};

export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String']['output'];
};

export type GenericCalculationErrorFragment = {
  __typename: 'GenericCalculationError';
  message: string;
  genericType: GenericCalculationErrorType;
};

export type ValidationCalculationErrorFragment = {
  __typename: 'ValidationCalculationError';
  message: string;
  type: string;
  code: string;
  description: string;
};

export type CreateCalculationMutationVariables = Exact<{
  amount: Scalars['BigDecimal']['input'];
  type: CalculationType;
  sendCountryCode: Scalars['CountryCode']['input'];
  sendCurrencyCode: Scalars['CurrencyCode']['input'];
  receiveCountryCode: Scalars['CountryCode']['input'];
  receiveCurrencyCode: Scalars['CurrencyCode']['input'];
  payOutMethodCode?: InputMaybe<Scalars['String']['input']>;
  correspondentId?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateCalculationMutation = {
  __typename?: 'Mutation';
  createCalculation: {
    __typename?: 'CreateCalculationResult';
    calculation?: {
      __typename?: 'Calculation';
      id?: string | null;
      isFree: boolean;
      informativeSummary: {
        __typename?: 'InformativeSummary';
        appliedPromotions?: Array<AppliedPromotions | null> | null;
        fee: {
          __typename?: 'Fee';
          type?: string | null;
          value: { __typename?: 'Money'; amount: unknown; currency: unknown };
        };
        discount?: {
          __typename?: 'Fee';
          type?: string | null;
          value: { __typename?: 'Money'; amount: unknown; currency: unknown };
        } | null;
        totalToPay: { __typename?: 'Money'; amount: unknown };
      };
      payInMethodsCalculations?: Array<{
        __typename?: 'PayInMethodCalculation';
        totalToPay: {
          __typename?: 'Money';
          amount: unknown;
          currency: unknown;
        };
        payInMethod: {
          __typename?: 'PayInMethod';
          name: string;
          transferRedirectionType?: string | null;
          id: string;
          icon?: { __typename?: 'Icon'; resolutions: Document } | null;
        };
      } | null> | null;
      send: { __typename?: 'Money'; currency: unknown; amount: unknown };
      receive: { __typename?: 'Money'; amount: unknown; currency: unknown };
      rounding?: {
        __typename?: 'Rounding';
        sendRoundingSeed?: unknown | null;
        receiveRoundingSeed?: unknown | null;
      } | null;
      exchangeRate: {
        __typename?: 'FxRate';
        value: unknown;
        crossedOutValue?: unknown | null;
      };
    } | null;
    errors: Array<
      | {
          __typename: 'GenericCalculationError';
          message: string;
          genericType: GenericCalculationErrorType;
        }
      | {
          __typename: 'ValidationCalculationError';
          message: string;
          type: string;
          code: string;
          description: string;
        }
    >;
  };
};

export type CreateProductsCalculationMutationVariables = Exact<{
  productsCalculationInput: ProductsCalculationInput;
}>;

export type CreateProductsCalculationMutation = {
  __typename?: 'Mutation';
  createProductsCalculation: {
    __typename?: 'ProductsCalculationResult';
    calculation?: {
      __typename?: 'ProductsCalculations';
      id?: string | null;
      productsCalculations?: Array<{
        __typename?: 'ProductCalculations';
        product?: {
          __typename?: 'Product';
          id?: string | null;
          name?: string | null;
        } | null;
        payInMethodCalculations?: Array<{
          __typename?: 'ProductPayInMethodCalculation';
          exchangeRate?: unknown | null;
          isDefault?: boolean | null;
          fee?: {
            __typename?: 'ProductFee';
            amount?: unknown | null;
            currency?: string | null;
            type?: string | null;
          } | null;
          totalToPay?: {
            __typename?: 'TotalToPay';
            amount?: unknown | null;
            currency?: string | null;
          } | null;
          payInMethod?: {
            __typename?: 'PayInMethod';
            id: string;
            name: string;
            description: string;
            deliveryTime?: string | null;
          } | null;
        } | null> | null;
      } | null> | null;
      corridor?: {
        __typename?: 'Corridor';
        payOutMethodCode?: string | null;
        correspondentId?: number | null;
        send?: {
          __typename?: 'CorridorCountry';
          country: string;
          currency: string;
        } | null;
        receive?: {
          __typename?: 'CorridorCountry';
          country: string;
          currency: string;
        } | null;
      } | null;
    } | null;
    errors?: Array<
      | {
          __typename?: 'GenericCalculationError';
          message: string;
          errorType: GenericCalculationErrorType;
        }
      | {
          __typename?: 'ValidationCalculationError';
          type: string;
          code: string;
          description: string;
          message: string;
        }
      | null
    > | null;
  };
};

export type ValidatePhoneNumberQueryVariables = Exact<{
  mobileNumber: Scalars['String']['input'];
  sendCountryCode: Scalars['CountryCode']['input'];
  receiveCountryCode: Scalars['CountryCode']['input'];
}>;

export type ValidatePhoneNumberQuery = {
  __typename?: 'Query';
  validatePhoneNumber:
    | { __typename?: 'PhoneNumberValidated'; _?: unknown | null }
    | { __typename?: 'PhoneNumberValidationFailed'; message: string };
};

export type SendPasswordResetLinkMutationVariables = Exact<{
  payload: ForgotPasswordInput;
}>;

export type SendPasswordResetLinkMutation = {
  __typename?: 'Mutation';
  sendPasswordResetLink?: unknown | null;
};

export type ResetPasswordMutationVariables = Exact<{
  payload: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword?: unknown | null;
};

export type ValidatePasswordResetLinkMutationVariables = Exact<{
  passwordResetLinkValidationInput: PasswordResetLinkValidationInput;
}>;

export type ValidatePasswordResetLinkMutation = {
  __typename?: 'Mutation';
  validatePasswordResetLink:
    | { __typename: 'PasswordResetLinkValidated'; _?: unknown | null }
    | {
        __typename: 'PasswordResetLinkValidationFailed';
        errorMessage?: string | null;
      };
};

export type CmsAccountDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type CmsAccountDetailsQuery = {
  __typename?: 'Query';
  accountDetails?: {
    __typename?: 'AccountDetails';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    reference?: string | null;
    gender?: string | null;
    language?: string | null;
    email?: { __typename?: 'Email'; email?: string | null } | null;
    country?: {
      __typename?: 'Country';
      name?: string | null;
      code: unknown;
      dialCode?: number | null;
      currency?: {
        __typename?: 'LocalizedCurrency';
        code: unknown;
        name: string;
      } | null;
    } | null;
    statistics?: {
      __typename?: 'AccountStatistics';
      lastTransactionDate?: string | null;
      transactionsCount?: number | null;
    } | null;
  } | null;
};

export type CorrespondentLocationsFromBffQueryVariables = Exact<{
  correspondentIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  countryCode: Scalars['CountryCode']['input'];
}>;

export type CorrespondentLocationsFromBffQuery = {
  __typename?: 'Query';
  correspondentLocations?: {
    __typename?: 'CorrespondentLocationConnection';
    edges?: Array<{
      __typename?: 'CorrespondentLocationEdge';
      node?: {
        __typename?: 'CorrespondentLocation';
        address?: string | null;
        city: string;
        correspondentName: string;
        country: string;
        hours?: string | null;
        officeName: string;
        phoneNumber?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetActiveCorridorsQueryVariables = Exact<{
  receiveCountry: Scalars['CountryCode']['input'];
  sendCountries:
    | Array<Scalars['CountryCode']['input']>
    | Scalars['CountryCode']['input'];
}>;

export type GetActiveCorridorsQuery = {
  __typename?: 'Query';
  getActiveCorridors: {
    __typename?: 'ActiveCorridorsResult';
    sendCountries: Array<unknown>;
  };
};

export type GetCorridorsBySendCountrySortedByReceiveCountryNameQueryVariables = Exact<{
  sendCountry: Scalars['CountryCode']['input'];
}>;

export type GetCorridorsBySendCountrySortedByReceiveCountryNameQuery = {
  __typename?: 'Query';
  corridorsBySendCountrySortedByReceiveCountryName: Array<{
    __typename?: 'Corridor';
    receiveCountry: {
      __typename?: 'Country';
      code: unknown;
      name?: string | null;
      dialCode?: number | null;
    };
    receiveCurrency: {
      __typename?: 'LocalizedCurrency';
      code: unknown;
      name: string;
    };
  }>;
};

export type ReceiveCountryCodesBySendCountryQueryVariables = Exact<{
  sendCountry: Scalars['CountryCode']['input'];
}>;

export type ReceiveCountryCodesBySendCountryQuery = {
  __typename?: 'Query';
  corridorsBySendCountry: Array<{
    __typename?: 'Corridor';
    receiveCountry: { __typename?: 'Country'; code: unknown };
  }>;
};

export type PayoutMethodsQueryVariables = Exact<{
  sendCountry: Scalars['CountryCode']['input'];
  receiveCountry: Scalars['CountryCode']['input'];
  receiveCurrency: Scalars['CurrencyCode']['input'];
}>;

export type PayoutMethodsQuery = {
  __typename?: 'Query';
  payOutMethods: Array<{
    __typename?: 'PayOutMethod';
    code?: string | null;
    payOutTimeEstimate?: string | null;
    correspondents?: Array<{
      __typename?: 'Correspondent';
      id: string;
      name?: string | null;
      payOutTime?: string | null;
    } | null> | null;
  } | null>;
};

export type GetReferralSchemeQueryVariables = Exact<{
  sendCountry: Scalars['CountryCode']['input'];
}>;

export type GetReferralSchemeQuery = {
  __typename?: 'Query';
  referralScheme: {
    __typename?: 'ReferralSchemeDetails';
    countryCode?: unknown | null;
    qualifyingConditions?: {
      __typename?: 'ReferralQualifyingConditions';
      minimalTransfer?: {
        __typename?: 'Money';
        amount: unknown;
        currency: unknown;
      } | null;
    } | null;
    reward?: {
      __typename?: 'Money';
      amount: unknown;
      currency: unknown;
    } | null;
    rewardForReferee?: {
      __typename?: 'Money';
      amount: unknown;
      currency: unknown;
    } | null;
  };
};

export type GetSendCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetSendCountriesQuery = {
  __typename?: 'Query';
  sendCountriesSorted: Array<{
    __typename?: 'Country';
    code: unknown;
    name?: string | null;
    dialCode?: number | null;
    currency?: {
      __typename?: 'LocalizedCurrency';
      code: unknown;
      name: string;
    } | null;
  }>;
};

export type ReferralCodeQueryVariables = Exact<{
  referrerId: Scalars['ID']['input'];
}>;

export type ReferralCodeQuery = {
  __typename?: 'Query';
  referralCode:
    | {
        __typename?: 'ReferAFriendValidationFailed';
        validationErrors: Array<ReferAFriendValidationError>;
      }
    | { __typename?: 'ReferralCodeDetails'; code: string };
};

export const GenericCalculationError = gql`
  fragment GenericCalculationError on GenericCalculationError {
    __typename
    message
    genericType: type
  }
`;
export const ValidationCalculationError = gql`
  fragment ValidationCalculationError on ValidationCalculationError {
    __typename
    message
    type
    code
    description
  }
`;
export const CreateCalculation = gql`
  mutation createCalculation(
    $amount: BigDecimal!
    $type: CalculationType!
    $sendCountryCode: CountryCode!
    $sendCurrencyCode: CurrencyCode!
    $receiveCountryCode: CountryCode!
    $receiveCurrencyCode: CurrencyCode!
    $payOutMethodCode: String
    $correspondentId: String
  ) {
    createCalculation(
      calculationInput: {
        amount: $amount
        send: { country: $sendCountryCode, currency: $sendCurrencyCode }
        type: $type
        receive: {
          country: $receiveCountryCode
          currency: $receiveCurrencyCode
        }
        payOutMethodCode: $payOutMethodCode
        correspondentId: $correspondentId
      }
    ) {
      calculation {
        id
        isFree
        informativeSummary {
          fee {
            value {
              amount
              currency
            }
            type
          }
          discount {
            value {
              amount
              currency
            }
            type
          }
          appliedPromotions
          totalToPay {
            amount
          }
        }
        payInMethodsCalculations {
          totalToPay {
            amount
            currency
          }
          payInMethod {
            name
            transferRedirectionType
            id
            icon {
              resolutions
            }
            transferRedirectionType
          }
        }
        send {
          currency
          amount
        }
        receive {
          amount
          currency
        }
        rounding {
          sendRoundingSeed
          receiveRoundingSeed
        }
        exchangeRate {
          value
          crossedOutValue
        }
      }
      errors {
        ...GenericCalculationError
        ...ValidationCalculationError
      }
    }
  }
  ${GenericCalculationError}
  ${ValidationCalculationError}
`;
export const CreateProductsCalculation = gql`
  mutation CreateProductsCalculation(
    $productsCalculationInput: ProductsCalculationInput!
  ) {
    createProductsCalculation(
      productsCalculationInput: $productsCalculationInput
    ) {
      calculation {
        id
        productsCalculations {
          product {
            id
            name
          }
          payInMethodCalculations {
            exchangeRate
            fee {
              amount
              currency
              type
            }
            totalToPay {
              amount
              currency
            }
            payInMethod {
              id
              name
              description
              deliveryTime
            }
            isDefault
          }
        }
        corridor {
          send {
            country
            currency
          }
          receive {
            country
            currency
          }
          payOutMethodCode
          correspondentId
        }
      }
      errors {
        ... on ValidationCalculationError {
          type
          code
          description
          message
        }
        ... on GenericCalculationError {
          message
          errorType: type
        }
      }
    }
  }
`;
export const ValidatePhoneNumber = gql`
  query validatePhoneNumber(
    $mobileNumber: String!
    $sendCountryCode: CountryCode!
    $receiveCountryCode: CountryCode!
  ) {
    validatePhoneNumber(
      phoneNumberValidationInput: {
        mobileNumber: $mobileNumber
        sendCountry: $sendCountryCode
        receiveCountry: $receiveCountryCode
      }
    ) {
      ... on PhoneNumberValidated {
        _
      }
      ... on PhoneNumberValidationFailed {
        message
      }
    }
  }
`;
export const SendPasswordResetLink = gql`
  mutation SendPasswordResetLink($payload: ForgotPasswordInput!) {
    sendPasswordResetLink(payload: $payload)
  }
`;
export const ResetPassword = gql`
  mutation ResetPassword($payload: ResetPasswordInput!) {
    resetPassword(payload: $payload)
  }
`;
export const ValidatePasswordResetLink = gql`
  mutation ValidatePasswordResetLink(
    $passwordResetLinkValidationInput: PasswordResetLinkValidationInput!
  ) {
    validatePasswordResetLink(
      passwordResetLinkValidationInput: $passwordResetLinkValidationInput
    ) {
      ... on PasswordResetLinkValidated {
        _
        __typename
      }
      ... on PasswordResetLinkValidationFailed {
        errorMessage
        __typename
      }
    }
  }
`;
export const CmsAccountDetails = gql`
  query CmsAccountDetails {
    accountDetails {
      id
      firstName
      lastName
      reference
      gender
      language
      email {
        email
      }
      country {
        name
        code
        dialCode
        currency {
          code
          name
        }
      }
      statistics {
        lastTransactionDate
        transactionsCount
      }
    }
  }
`;
export const CorrespondentLocationsFromBff = gql`
  query CorrespondentLocationsFromBFF(
    $correspondentIds: [ID!]!
    $countryCode: CountryCode!
  ) {
    correspondentLocations(
      correspondentLocationInput: {
        correspondentIds: $correspondentIds
        countryCode: $countryCode
      }
      first: 999
    ) {
      edges {
        node {
          address
          city
          correspondentName
          country
          hours
          officeName
          phoneNumber
        }
      }
    }
  }
`;
export const GetActiveCorridors = gql`
  query GetActiveCorridors(
    $receiveCountry: CountryCode!
    $sendCountries: [CountryCode!]!
  ) {
    getActiveCorridors(
      corridorsToSearchInput: {
        receiveCountry: $receiveCountry
        sendCountries: $sendCountries
      }
    ) {
      sendCountries
    }
  }
`;
export const GetCorridorsBySendCountrySortedByReceiveCountryName = gql`
  query GetCorridorsBySendCountrySortedByReceiveCountryName(
    $sendCountry: CountryCode!
  ) {
    corridorsBySendCountrySortedByReceiveCountryName(
      sendCountry: $sendCountry
    ) {
      receiveCountry {
        code
        name
        dialCode
      }
      receiveCurrency {
        code
        name
      }
    }
  }
`;
export const ReceiveCountryCodesBySendCountry = gql`
  query ReceiveCountryCodesBySendCountry($sendCountry: CountryCode!) {
    corridorsBySendCountry(sendCountry: $sendCountry) {
      receiveCountry {
        code
      }
    }
  }
`;
export const PayoutMethods = gql`
  query PayoutMethods(
    $sendCountry: CountryCode!
    $receiveCountry: CountryCode!
    $receiveCurrency: CurrencyCode!
  ) {
    payOutMethods(
      payOutMethodsInput: {
        sendCountry: $sendCountry
        receiveCountry: $receiveCountry
        receiveCurrency: $receiveCurrency
      }
    ) {
      code
      payOutTimeEstimate
      correspondents {
        id
        name
        payOutTime
      }
    }
  }
`;
export const GetReferralScheme = gql`
  query GetReferralScheme($sendCountry: CountryCode!) {
    referralScheme(country: $sendCountry) {
      countryCode
      qualifyingConditions {
        minimalTransfer {
          amount
          currency
        }
      }
      reward {
        amount
        currency
      }
      rewardForReferee {
        amount
        currency
      }
    }
  }
`;
export const GetSendCountries = gql`
  query GetSendCountries {
    sendCountriesSorted(countrySortingAttributes: { countrySortBy: NAME }) {
      code
      name
      dialCode
      currency {
        code
        name
      }
    }
  }
`;
export const ReferralCode = gql`
  query referralCode($referrerId: ID!) {
    referralCode(referrerId: $referrerId) {
      ... on ReferralCodeDetails {
        code
      }
      ... on ReferAFriendValidationFailed {
        validationErrors
      }
    }
  }
`;
