import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Block, BLOCKS, Inline, INLINES } from '@contentful/rich-text-types';
import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';

import { RichTextProps } from '@/components/rich-text/rich-text.types';

import { Link } from '../link';

const HyperLinkRenderer = (
  node: Block | Inline,
  children: ReactNode,
  newTabLinks: boolean | undefined,
  options: RichTextProps['options'],
  className: string | undefined,
): ReactNode => {
  return (
    <Link
      data-testid="link"
      href={node.data.uri || node.data.url}
      target={newTabLinks ? '_blank' : '_self'}
      className={className}
      {...options?.[INLINES.HYPERLINK]}
    >
      {children}
    </Link>
  );
};

const TextRenderer = (
  node: Block | Inline,
  children: ReactNode,
  options: RichTextProps['options'],
  className: string | undefined,
) => {
  const variant = options ? options.variant : 'body1';
  const align = options ? options.align : 'left';

  return (
    <Typography
      data-testid="text"
      variant={variant}
      align={align}
      component="p"
      paragraph={true}
      className={className}
    >
      {children}
    </Typography>
  );
};

export const RichText: React.FC<RichTextProps> = props => {
  const { json, className, options, newTabLinks } = props;

  const component = documentToReactComponents(json, {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) =>
        TextRenderer(node, children, options, className),
      [INLINES.HYPERLINK]: (node, children) =>
        HyperLinkRenderer(node, children, newTabLinks, options, className),
    },
  });

  return <div>{component}</div>;
};
