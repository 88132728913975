import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import clsx from 'clsx';
import React, { FC } from 'react';

import { useStyles } from '@/components/validator-pannel/validator-panel.styles';
import { ValidationPanelProps } from '@/components/validator-pannel/validator-panel.type';

export const ValidatorPanel: FC<ValidationPanelProps> = ({ result }) => {
  const classes = useStyles();

  return (
    <ul className={classes.panel}>
      {result.map(element => (
        <li key={element.label} className={classes.line}>
          {element.isValid ? (
            <CheckCircleIcon
              className={classes.check}
              data-testid="check-circle-icon"
            />
          ) : (
            <CancelIcon
              className={classes.cancel}
              data-testid="cancel-circle-icon"
            />
          )}
          <span className={clsx('subtitle2', classes.label)}>
            {element.label}
          </span>
        </li>
      ))}
    </ul>
  );
};
