import {
  bffSchema,
  isEmailValidBrowserCheck,
  pushToDataLayer,
} from '@wr/web-shared';
import { useEffect, useState } from 'react';

import { createClientSideBFFClient } from '@/apollo/bff';
import { GA_EVENT_FORGOTTEN_PASSWORD_CONTINUE } from '@/constants';
import { useCallbackWithErrorHandling } from '@/hooks';

const FIVE_MINUTES_IN_SECONDS = 300;

export const useForgottenPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showFieldError, setShowFieldError] = useState(false);

  const isEmailValid = isEmailValidBrowserCheck(email);
  const shouldShowInputErrorState = showFieldError && !isEmailValid;

  const [emails, setEmails] = useState<string[]>([]);
  const [expiryTimes, setExpiryTimes] = useState<number[]>([]);
  const [timers, setTimers] = useState<number[]>([]);
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);

  useEffect(() => {
    const storedEmails = JSON.parse(
      sessionStorage.getItem('resetPasswordEmails') || '[]',
    );
    const storedExpiryTimes = JSON.parse(
      sessionStorage.getItem('resetPasswordExpiryTimes') || '[]',
    );

    const newTimers = storedExpiryTimes.map((expiryTime: number) => {
      const currentTime = Date.now();
      const remainingTime = expiryTime - currentTime;
      return Math.max(0, Math.floor(remainingTime / 1000));
    });

    setEmails(storedEmails);
    setExpiryTimes(storedExpiryTimes);
    setTimers(newTimers);

    setIsTimerRunning(newTimers.some((timer: number) => timer > 0));
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimers(prevTimers => {
        const newTimers = prevTimers.map(timer => Math.max(0, timer - 1));
        const anyTimerRunning = newTimers.some(timer => timer > 0);
        setIsTimerRunning(anyTimerRunning);
        if (!anyTimerRunning) {
          setEmails([]);
          setExpiryTimes([]);
          sessionStorage.removeItem('resetPasswordEmails');
          sessionStorage.removeItem('resetPasswordExpiryTimes');
        }
        return newTimers;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleSendResetEmail = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    pushToDataLayer(GA_EVENT_FORGOTTEN_PASSWORD_CONTINUE);

    try {
      setIsLoading(true);
      const currentTime = Date.now();
      const expiryTime = currentTime + FIVE_MINUTES_IN_SECONDS * 1000;

      const updatedEmails = [...emails, email];
      const updatedExpiryTimes = [...expiryTimes, expiryTime];

      setEmails(updatedEmails);
      setExpiryTimes(updatedExpiryTimes);
      setTimers(prevTimers => [...prevTimers, FIVE_MINUTES_IN_SECONDS]);
      setIsTimerRunning(true);

      sessionStorage.setItem(
        'resetPasswordEmails',
        JSON.stringify(updatedEmails),
      );
      sessionStorage.setItem(
        'resetPasswordExpiryTimes',
        JSON.stringify(updatedExpiryTimes),
      );

      await createClientSideBFFClient().mutate({
        mutation: bffSchema.SendPasswordResetLink,
        variables: {
          payload: {
            email,
          },
        },
      });
      setSubmittedEmail(email);
    } finally {
      setShowFieldError(false);
      setIsLoading(false);
    }
  };

  const handleSubmitWithErrorHandling = useCallbackWithErrorHandling(
    'Error while submission forgotten password form',
    handleSendResetEmail,
  );

  const handleOnChangeField = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setEmail(e.target.value);
  };

  const isEmailInListWithTimer = emails.includes(email);
  const isDisabledSubmitButton =
    !isEmailValid ||
    isLoading ||
    (isTimerRunning &&
      emails.includes(email) &&
      timers[emails.indexOf(email)] > 0);

  return {
    handleSubmit: handleSubmitWithErrorHandling,
    handleOnChangeField,
    handleOnBlurField: () => setShowFieldError(true),
    email,
    submittedEmail,
    shouldShowInputErrorState,
    isDisabledSubmitButton,
    isTimerRunning,
    timer: isEmailInListWithTimer ? timers[emails.indexOf(email)] : 0,
    isEmailInListWithTimer,
  };
};
