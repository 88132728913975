import { ExchangeRateCalculationType } from '@wr/web-ui';

import { CalculatorActionTypes, CalculatorContextType } from '@/context';

export const INITIAL_CALCULATOR_CONTEXT: CalculatorContextType = {
  sendAmount: 0,
  receiveAmount: 0,
  calculationAmount: 1000,
  calculationType: ExchangeRateCalculationType.Send,
  sendCountry: null,
  receiveCountry: null,
  payoutMethodId: null,
  payoutMethods: [],
  isLoading: false,
  exchangeRate: {
    discount: 0,
    fee: 0,
    totalToPay: 0,
    conversionRate: 0,
    crossedOutRate: 0,
    hasPromo: false,
  },
};

export const INITIAL_CALCULATOR_ACTIONS: CalculatorActionTypes = {
  setSendAmount: () => {},
  setReceiveAmount: () => {},
  setCalculationAmount: () => {},
  setCalculationType: () => {},
  setSendCountry: () => {},
  setReceiveCountry: () => {},
  setPayoutMethodId: () => {},
  setPayoutMethods: () => {},
  setExchangeRate: () => {},
  setIsLoading: () => {},
};
