import { BLOCKS } from '@contentful/rich-text-types';
import { Typography } from '@mui/material';
import { Container as UIContainer, CountryFlag } from '@wr/web-ui';
import React from 'react';

import { Anchor } from '@/components';
import { RichTextContentRenderer } from '@/components/rich-text-content-renderer';

import { useCurrencyInformation } from './cex-currency-information.hooks';
import { useStyles } from './cex-currency-information.styles';
import { CexCurrencyInformationProps } from './cex-currency-information.types';

export const CexCurrencyInformation: React.FC<CexCurrencyInformationProps> = ({
  sendCurrencyCode,
  receiveCurrencyCode,
}) => {
  const classes = useStyles();

  const currencies = useCurrencyInformation({
    sendCurrencyCode,
    receiveCurrencyCode,
  });

  if (!currencies?.length) {
    return null;
  }

  return (
    <UIContainer
      component="section"
      className={classes.container}
      data-testid="cex-currency-information"
    >
      <Typography
        data-testid="title-template-heading"
        className={classes.title}
        variant="h2"
      >
        Currency Information
      </Typography>

      <div className={classes.infoCardWrapper}>
        {currencies?.map(currency => {
          return (
            <Anchor
              key={currency.currencyCode}
              className={classes.currencyLink}
              url={currency.pageSlug}
            >
              <div
                className={classes.currencyContainer}
                data-testid={`cex-currency-information-${currency.currencyCode}`}
              >
                <div className={classes.currencyHeading}>
                  <CountryFlag
                    type="circle"
                    className={classes.flag}
                    code={currency?.countryCode ?? ''}
                  />
                  <Typography variant="h3">
                    {`${currency.currencyName} (${currency.currencyCode})`}
                  </Typography>
                </div>
                {currency?.description?.json && (
                  <RichTextContentRenderer
                    json={currency.description.json}
                    options={{
                      [BLOCKS.PARAGRAPH]: {
                        variant: 'body2',
                        className: classes.currencyContent,
                      },
                    }}
                  />
                )}
              </div>
            </Anchor>
          );
        })}
      </div>
    </UIContainer>
  );
};
