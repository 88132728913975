import { Box, Typography } from '@mui/material';
import { CountryFlag } from '@wr/web-ui';
import React from 'react';

import { Anchor } from '@/components/anchor';

import { CexCurrencyExchangeRate } from './cex-currency-conversion-rates.exchange';
import { useStyles } from './cex-currency-conversion-rates.styles';
import { CexCurrencyConversionRatesProps } from './cex-currency-conversion-rates.types';

export const CexCurrencyConversionRates: React.FC<CexCurrencyConversionRatesProps> = ({
  sendCurrency,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.container}
      data-testid={'cex-conversion-rates-container'}
    >
      <div className={classes.heading}>
        <Typography variant="body1">
          <b>{`From ${sendCurrency.currencyCode}`}</b>
        </Typography>
      </div>

      {sendCurrency.topCurrencyPairs?.items.map(currency => (
        <Anchor
          className={classes.corridorLink}
          key={currency?.currencyCode}
          url={currency?.pageSlug}
        >
          <div className={classes.corridorRow}>
            <div className={classes.flexWrapper}>
              <CountryFlag code={currency?.countryCode ?? ''} />
              <Typography variant="body1">{currency?.currencyName}</Typography>
            </div>
            <div className={classes.flexWrapper}>
              <Typography variant="body1">
                <b>{`${sendCurrency.currencyCode}/${currency?.currencyCode}`}</b>
              </Typography>
              <Typography
                paragraph
                data-testid={`exchange-rate-${currency?.currencyCode}`}
              >
                <CexCurrencyExchangeRate
                  sendCurrency={{
                    countryCode: sendCurrency?.countryCode ?? '',
                    currencyCode: sendCurrency?.currencyCode ?? '',
                  }}
                  receiveCurrency={{
                    countryCode: currency?.countryCode ?? '',
                    currencyCode: currency?.currencyCode ?? '',
                  }}
                />
              </Typography>
            </div>
          </div>
        </Anchor>
      ))}
    </Box>
  );
};
