import { isClient } from './is-client';

export function isEmailValidBrowserCheck(email: string) {
  if (!isClient() || !email) {
    return false;
  }
  const htmlEmailInput = document.createElement('input');
  const maxLength = 255;
  htmlEmailInput.type = 'email';
  htmlEmailInput.value = email;
  return htmlEmailInput.validity.valid && email.length < maxLength;
}
