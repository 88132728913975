import { Box, Skeleton, Typography } from '@mui/material';
import { Select } from '@wr/web-ui';
import React, { useContext, useMemo } from 'react';

import { CalculatorContext } from '@/context';

import { useStyles } from './cex-calculator.styles';
import { CalculatorFooterProps, DetailBoxProps } from './cex-calculator.types';

export const DetailBox: React.FC<DetailBoxProps> = ({
  label,
  value,
  isLoading,
  highlight = false,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.details}>
      <Typography variant="body1" component="span">
        {label}
      </Typography>
      {isLoading ? (
        <Skeleton variant="rounded" width="50px" height="20px" />
      ) : (
        <Typography
          className={classes.detailValue}
          variant="body1"
          component="span"
          fontWeight="bold"
          color={highlight ? '#128387 ' : 'inherit'}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
};

export const CexCalculatorFooter: React.FC<CalculatorFooterProps> = ({
  feeLabel,
  transferTimeLabel,
  totalToPayLabel,
  payoutMethodsLabel,
  onChangePayoutMethod,
  instantDiscountLabel,
  hasErrors = false,
}) => {
  const classes = useStyles();
  const [
    { payoutMethods, payoutMethodId, exchangeRate, isLoading, sendCountry },
  ] = useContext(CalculatorContext);

  const selectedPayoutMethod = useMemo(() => {
    return payoutMethods?.find(method => method.code === payoutMethodId);
  }, [payoutMethodId, payoutMethods]);

  const payOutTimeEstimate = selectedPayoutMethod?.payOutTimeEstimate;

  return (
    <div>
      <Box
        className={classes.payoutMethodContainer}
        data-testid="receive-method-container"
      >
        <Select
          isDisabled={isLoading}
          name={payoutMethodsLabel || ''}
          value={selectedPayoutMethod?.code || ''}
          data-testid="receive-method"
          options={payoutMethods.map(payoutMethod => ({
            value: payoutMethod.code,
            label: payoutMethod.name,
          }))}
          label={payoutMethodsLabel || ''}
          onChange={(dropdownValue): void => {
            onChangePayoutMethod(dropdownValue);
          }}
          forwardedProps={{
            displayEmpty: true,
            renderValue: () =>
              isLoading ? <span /> : <span>{selectedPayoutMethod?.name}</span>,

            fullWidth: true,
            color: 'primary',
            variant: 'outlined',
          }}
          className={classes.receiveMethodSelect}
        />
      </Box>
      {!hasErrors && (
        <div className={classes.feeInfo}>
          <DetailBox
            label={feeLabel}
            value={`${exchangeRate?.fee} ${sendCountry?.currency}`}
            isLoading={isLoading}
          />
          {exchangeRate && exchangeRate.discount > 0 && (
            <DetailBox
              highlight
              label={instantDiscountLabel}
              value={`-${exchangeRate?.discount} ${sendCountry?.currency}`}
              isLoading={isLoading}
            />
          )}
          <DetailBox
            highlight
            label={transferTimeLabel}
            value={payOutTimeEstimate}
            isLoading={isLoading}
          />
          <DetailBox
            label={totalToPayLabel}
            value={`${exchangeRate?.totalToPay} ${sendCountry?.currency}`}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  );
};
