import { DEFAULT_LANGUAGE, DEFAULT_LOCALE } from '@wr/web-shared';
import { GetStaticPropsContext } from 'next';
import { IResult } from 'ua-parser-js';

export * from './contentful';
export * from './interpolate';
export * from './corridor';
export * from './url';
export * from './selectors';
export * from './country';
export * from './browser';
export * from './logger';
export * from './riskified-tracking';
export * from './contentful-client';
export * from './bff-client';
export * from './logger';
export * from './refer-a-friend';
export * from './search-dialog';
export * from './locale';
export * from './arrays';
export * from './component-resolver';
export * from './intl';
export * from './date';
export * from './redirects';

export const getLocaleFromContext = ({
  locale = DEFAULT_LANGUAGE,
}: GetStaticPropsContext) =>
  locale === DEFAULT_LOCALE ? DEFAULT_LANGUAGE : locale;

export const getDeviceModelFromHeaders = (uaHeaders: IResult) => {
  return `${uaHeaders?.device?.model} ${uaHeaders?.browser.name}`;
};
