/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ApolloClient,
  ApolloConfig,
  COOKIES,
  createApolloClientLink,
  createBFFClient,
  DEFAULT_LANGUAGE,
  PerimeterXBlockStrategy,
} from '@wr/web-shared';
import { getCookie } from 'cookies-next';
import { Logger, LoggerOptions } from 'pino';
import { v4 as uuidv4 } from 'uuid';

import { logger } from '@/utils';

let serverSideClient: ApolloClient | undefined = undefined;

export type ApolloClientContext = {
  logger: Logger<LoggerOptions>;
  token: string | undefined;
  locale: string;
  gaClientId: string | undefined;
  trueClientIp: string | undefined;
  deviceModel: string | undefined;
  platformGreenfield?: boolean | undefined;
};

export function createApolloClientContext(
  logger: Logger<LoggerOptions>,
  token: string | undefined,
  locale: string,
  gaClientId: string | undefined,
  trueClientIp: string | undefined,
  deviceModel: string | undefined,
  platformGreenfield?: boolean | undefined,
): ApolloClientContext {
  return {
    logger,
    token,
    locale,
    gaClientId,
    trueClientIp,
    deviceModel,
    platformGreenfield,
  };
}

export function createIsomorphicApolloClient(
  apolloClientContext: ApolloClientContext | undefined,
) {
  return apolloClientContext
    ? getServerSideBFFClient(apolloClientContext)
    : createClientSideBFFClient();
}

/**
 * as we need to set correct auth token this function has to be called before EACH request
 */
export const getServerSideBFFClient = ({
  logger,
  token,
  locale,
  gaClientId,
  trueClientIp,
  deviceModel,
  platformGreenfield,
}: ApolloClientContext) => {
  const apolloConfig: ApolloConfig = {
    // for performance and to avoid perimterX blocking use internal bff service url on server side
    url: process.env.INTERNAL_BFF_GQL_URI!,
    logger,
    headers: {
      'Accept-Language': locale || DEFAULT_LANGUAGE,
      'X-WR-Platform': 'Web',
      'X-WR-RequestID': uuidv4(),
      'X-WR-ClientId': gaClientId || '',
      'True-Client-Ip': trueClientIp || '',
      'X-WR-DeviceModel': deviceModel || '',
      'X-WR-DeviceId': deviceModel || '',
      'X-WR-PlatformGreenfield': platformGreenfield ? 'true' : 'false',
      ...(token ? { Authorization: token } : {}),
    },
    defaultOptions: {
      // disable cache because by default apollo doesn't consider header when it creates cache keys
      // for example with cache enabled it returns cached accountDetails for a wrong user
      // we could use apollo cache keyArgs function to create cache keys based on header
      // but probably it doesn't make much sense as we have multiple kubernetes instances and cache will not help us all the time
      // We still of cause can enable cache on query level bases, when there is no user specific data
      // but again probably it makes sense to research solution like centralized redis cache
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    },
  };
  if (!serverSideClient) {
    serverSideClient = createBFFClient(apolloConfig);
  }

  serverSideClient.setLink(createApolloClientLink(apolloConfig));

  return serverSideClient;
};

/**
 * on client side browser will automatically send cookies with auth token
 * @param perimeterXBlockStrategy 'ThrowAndReloadPage' is more robust
 * strategy, apollo client throws error, PerimeterXCaptchaModal hides main content of the page
 * and on user captcha success it reloads the page, 'IgnorePXError' can be used
 * when we don't want to loose user data
 */
export const createClientSideBFFClient = (
  perimeterXBlockStrategy: PerimeterXBlockStrategy = 'ThrowAndReloadPage',
) => {
  // take locale from first url segment like /en/page-name -> en
  const locale =
    typeof document !== 'undefined'
      ? document.location.pathname.split('/')[1]
      : DEFAULT_LANGUAGE;
  const gaClientId = getCookie(COOKIES.GA_CLIENT_ID);
  const headers: Record<string, string> = {
    'Accept-Language': locale || DEFAULT_LANGUAGE,
    'X-WR-Platform': 'Web',
    'X-WR-RequestID': uuidv4(),
    'X-WR-ClientId': gaClientId || '',
  };

  return createBFFClient({
    url: process.env.NEXT_PUBLIC_BFF_GQL_URI!,
    logger,
    headers,
    perimeterXBlockStrategy,
  });
};
