import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  container: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(16, 12),
    },
  },
  alert: {
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.25rem',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  description: {
    marginBottom: theme.spacing(2),
    lineHeight: '1.875rem',
    letterSpacing: '0.009375rem',
  },
  helpText: {
    '& h3': {
      fontSize: '1.25rem',
      textAlign: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(0.75),
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  stickyBanner: {
    paddingBottom: '1rem',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '1.5rem',
    },
  },
  resetTimerText: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: '1rem',
    color: theme.palette.grey[400],
  },
}));
