import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { CorrespondentLocationsProps } from './correspondent-locations.types';

const useStyles = makeStyles<Theme, Partial<CorrespondentLocationsProps>>(
  theme => ({
    section: {
      padding: theme.spacing(8, 0),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    title: {
      flex: 1,
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        marginBottom: 0,
        fontSize: '1.75rem',
      },
    },
    tableContainer: {
      marginTop: theme.spacing(4),
      width: '100%',
    },
    tableCell: props => ({
      width: `${(1 / (props.locationDetailsIds?.length || 0)) * 100}%`,
      background: theme.palette.common.white,
    }),
  }),
);

export default useStyles;
